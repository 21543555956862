import ApiService from "@/common/api.service";
const PATH = 'selects-data';

// action types
export const GET_SELECTS_DATA = "getSelectsData";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_SELECTS_DATA](context, selects) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH, { selects: selects })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
