import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store";
import router from "../router";
import JwtService from "@/common/jwt.service";
import { API_URL, API_PATH } from "@/common/config";
import { CHECK_TOKEN } from "../store/auth.module";
import { SHOW_SNACK } from "@/store/snack.module";

/**
 * Check if access token has expired before request and refresh it
 */
const createAxiosResponseInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response && error.response.status === 422){
                let errors = error.response.data.errors;
                let message = errors && Object.keys(errors).length>0 && errors[Object.keys(errors)[0]].length>0?errors[Object.keys(errors)[0]][0]:'';
                if (message) {
                  store.dispatch(SHOW_SNACK, {
                      message: message,
                      timeout: 5000,
                      type: "error"
                  });
                }
                return Promise.reject(error);
            }
            if(error.response && error.response.status > 499){
                store.dispatch(SHOW_SNACK, {
                    message: 'Unexpected error! Please contact the technical team if the problem persist.',
                    timeout: 5000,
                    type: "error"
                });
                return Promise.reject(error);
            }
            // Reject promise if usual error
            if (
                !error.response ||
                error.response.status !== 401 ||
                error.response.config.url === "oauth/token"
            ) {
                return Promise.reject(error);
            }

            /*
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);
            store
                .dispatch(CHECK_TOKEN)
                .then(data => {
                    error.response.config.headers["Authorization"] =
                        "Bearer " + data.access_token;
                    return axios(error.response.config);
                })
                .catch(error => {
                    let route = {
                        name: 'login'
                    };

                    if (window.location.pathname.startsWith('/copper-embedded-apps/')) {
                        route.name = 'copper-embedded-apps-login';
                        route.query = { previousRoute: window.location.pathname + window.location.search };
                    }

                    router.push(route);
                    return Promise.reject(error);
                })
                .finally(createAxiosResponseInterceptor);
        }
    );
};

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
    axios.interceptors.request.use(config => {
        let token = JwtService.getToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    });
};

const getUrl = path => {
    return API_PATH + path;
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        setHeader();
        createAxiosResponseInterceptor();
    },

    search(resource, q) {
        return Vue.axios
            .get(getUrl(resource), {
                params: {
                    q: q
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV !== "production") {
                    console.log(error);
                }
                throw new Error(`[KT] ApiService ${error}`);
            });
    },

    query(resource, params) {
        return Vue.axios
            .get(getUrl(resource), {
                params: params
            })
            .catch(error => {
                if (process.env.NODE_ENV !== "production") {
                    console.log(error);
                }
                return Promise.reject(error);
                throw new Error(`[KT] ApiService ${error}`);
            });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        return Vue.axios.get(getUrl(`${resource}/${slug}`)).catch(error => {
            if(error.response.status === 403){
                store.dispatch(SHOW_SNACK, {
                    message: error.response.data.message,
                    timeout: 20000,
                    type: "error"
                });
            }

            if (process.env.NODE_ENV !== "production") {
                console.log(error);
            }

            return Promise.reject(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },
    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @param type
     * @returns {*}
     */
    getType(resource, params) {

        let slug = params.join('/');

        return Vue.axios.get(getUrl(`${resource}/${slug}`)).catch(error => {
            if(error.response.status === 403){
                store.dispatch(SHOW_SNACK, {
                    message: error.response.data.message,
                    timeout: 20000,
                    type: "error"
                });
            }

            if (process.env.NODE_ENV !== "production") {
                console.log(error);
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
    },
    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    getRB(resource, slug = "") {
        return Vue.axios.get(getUrl(`${resource}/${slug}`)).catch(error => {
            if(error.response.status === 403){
                store.dispatch(SHOW_SNACK, {
                    message: error.response.data.message,
                    timeout: 20000,
                    type: "error"
                });
            }

            if(error.response.status === 404){
                store.dispatch(SHOW_SNACK, {
                    message: 'The resource you want to open was not found',
                    timeout: 20000,
                    type: "error"
                });
            }
            if (process.env.NODE_ENV !== "production") {
                console.log(error);
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(getUrl(resource), params);
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    resort(resource) {
        return Vue.axios.post(getUrl(`${resource}/resort`));
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(getUrl(`${resource}/${slug}`), params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(getUrl(`${resource}`), params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(getUrl(resource)).catch(error => {
            if (process.env.NODE_ENV !== "production") {
                console.log(error);
            }
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    deleteSpecific(resource, slug) {
        return Vue.axios.delete(getUrl(`${resource}/${slug}`)).catch(error => {
            if (process.env.NODE_ENV !== "production") {
                console.log(error);
            }
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
