import { countBy } from "lodash";

function removeWsFromSelection() {
    let selection = getSelection();
    if (selection.toString().length === 0) {
        return;
    }
    let range = selection.getRangeAt(0);
    let regex = /\s+$/;
    let container = range.endContainer;
    let method = range.setEnd;

    let match = regex.exec(selection.toString());
    if (match) {
        let ind = match.index;
        if (ind > 0) {
            // ind is the first non-ws char from the start or first ws char from the end,
            // hence (startOffset + ind)
            if (
                method == range.setEnd &&
                range.startOffset + ind >= range.endContainer.length
            ) {
                match = regex.exec(range.endContainer.textContent);
                if (match) {
                    range.setEnd(range.endContainer, match.index);
                }
            } else {
                method.call(range, container, range.startOffset + ind);
            }
            let rng = range.cloneRange();
            selection.removeAllRanges();
            selection.addRange(rng);
        }
    }
}
export default {
    replaceRange(s, start, end, substitute) {
        return s.substring(0, start) + substitute + s.substring(end);
    },
    getObject() {
        removeWsFromSelection();
        let sel = getSelection();

        if (sel.toString().length === 0) {
            return null;
        }

        // Check if selection is on more than one nodes
        if (sel.anchorNode.wholeText !== sel.focusNode.wholeText) {
            return null;
        }

        let object = {
            item: null,
            text: sel.toString(),
            start:
                sel.anchorOffset < sel.focusOffset
                    ? sel.anchorOffset
                    : sel.focusOffset,
            end:
                sel.focusOffset > sel.anchorOffset
                    ? sel.focusOffset
                    : sel.anchorOffset,
            wholeText: sel.anchorNode.wholeText
        };

        let wholeLeftText =  object.wholeText;
        let wholeParentText = sel.anchorNode.parentElement;
        let clone = wholeParentText.cloneNode(true);
        let labelElement = clone.getElementsByClassName("v-chip--label");

        while (labelElement[0]) {
            labelElement[0].parentNode.removeChild(labelElement[0]);
        }
        //TODO need to update selection element with part of string with better way
        let consoleParts = clone.innerText.split(wholeLeftText);
        // let consoleParts1 = clone.innerText.split(object.text);
        if (consoleParts.length > 0) {
            let part = consoleParts;
            object.realStart = part[0].length + object.start;
            object.realEnd = part[0].length + object.end;
        }

        clone.remove();

        return object;
    },
    replace(selection, text, replace) {
        let selectionReplace = this.replaceRange(
            selection.wholeText,
            selection.start,
            selection.end,
            replace
        );

        let selectionReplaceFull = text.replace(
            selection.wholeText,
            selectionReplace
        );

        return selectionReplaceFull;
    },
    clearById(text, id) {
        let doc = new DOMParser().parseFromString(text, "text/html");
        let el = doc.getElementById(id);
        if (!el) {
            return text;
        }
        let newEl = document.createElement("newel");
        newEl.innerHTML = this.decodeHtml(el.getAttribute("content"));
        el.parentNode.replaceChild(newEl, el);
        let result = doc.body.innerHTML.replace(/<\/?newel[^>]*>/g, "");
        return result;
    },
    getComponentInnerHtml(data, html, uuid) {
        html = this.encodeHtml(html);
        return `<tpsegment id="${uuid}" uuid="${uuid}" class="disable-selection" :segment-data='${JSON.stringify(
            data
        )}' content='${html}' @remove='removeSegment'></tpsegment>`;
    },
    encodeHtml (string) {
        var entityMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;',
            '{': '&#123;',
            '}': '&#125;',
            '(': '&#40;',
            ')': '&#41;',
          };
        return encodeURIComponent(String(string).replace(/[&<>"'(){}`=\/]/g, function (s) {
          return entityMap[s];
        }));
    },
    decodeHtml (string) {
        var entityMap = [
            {key:'&',value:'&amp;'},
            {key:'>',value:'&lt;'},
            {key:'<',value:'&gt;'},
            {key:'"',value:'&quot;'},
            {key:"'",value:'&#39;'},
            {key:'/',value:'&#x2F;'},
            {key:'`',value:'&#x60;'},
            {key:'=',value:'&#x3D;'},
            {key:'{',value:'&#123;'},
            {key:'}',value:'&#125;'},
            {key:'(',value:'&#40;'},
            {key:')',value:'&#41;'},
        ]
        string = decodeURIComponent(string);
         entityMap.forEach(x => {
            string = string.replace(new RegExp(x.value, "g"),x.key)
         });
        return string;
    },
    getClosestIndex(text, find, goal) {
        if (goal === 0) {
            return text.indexOf(find);
        }
        var closest = -1;
        var regex = new RegExp(this.escape(find), "gi");
        var result = [];
        var indices = [];
        while ((result = regex.exec(text))) {
            indices.push(result.index);
        }

        if (indices.length) {
            closest = indices.reduce(function(prev, curr) {
                return Math.abs(curr - goal) < Math.abs(prev - goal)
                    ? curr
                    : prev;
            });
        }
        return closest;
    },
    escape(S){
        // 1. let str be ToString(S).
        // 2. ReturnIfAbrupt(str).
        let str = String(S);
        // 3. Let cpList be a List containing in order the code
        // points as defined in 6.1.4 of str, starting at the first element of str.
        let cpList = Array.from(str[Symbol.iterator]());
        // 4. let cuList be a new List
        let cuList = [];
        // 5. For each code point c in cpList in List order, do:
        for(let c of cpList){
          // i. If c is a SyntaxCharacter then do:
          if("^$\\.*+?()[]{}|".indexOf(c) !== -1){
            // a. Append "\" to cuList.
            cuList.push("\\");
          }
          // Append c to cpList.
          cuList.push(c);
        }
        //6. Let L be a String whose elements are, in order, the elements of cuList.
        let L = cuList.join("");
        // 7. Return L.
        return L;
    },
    percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
     }
};
