// USA
export const locale = {
    GENERAL: {
      APP_TITLE: "VIMS",
      UNEXPECTED_ERROR: "Unexpected error! Please contact the technical team if the problem persist.",
      FORBIDDEN:"You do not have the right permissions.",
      DELETE_CONFIRM: "Please confirm delete!",
      SELECT_ALL_CONFIRM: "Please confirm all select/deselect!",
      SUCCESS_MESSAGE: "Success!",
      SUCCESS_UPDATE_MESSAGE: "Successfully updated!",
      LABELS: {
        CONTINUE_BUTTON: "Continue",
        BACK_BUTTON: "Back",
        DOWNLOAD_BUTTON: "Download",
        CREATE_BUTTON: "Create",
        ALL: "All",
        SELECT_ALL_OPTION: "Select All",
        SAVE: "Save",
        EDIT: "Edit",
        EDIT_BUTTON: "Edit",
        CLOSE_BUTTON: "Close",
        CLONE_INTERESTS_BUTTON: "Clone Interests",
        RELOAD_PAGE: "Reload Page",
        ORDER: "Order",
        OFFER_GROUP: "Offer Group",
        SEARCH: "Search",
        SHOW_ONLY_SELECTED: "Only Selected",
        ADD_NEW_BELOW: "Add New Below",
        HEADERS: "Headers",
        SEARCH: "Search",
        TEMPERATURE: "Temperature",
        ORDER: "Order",
        LEAD: "Lead",
        FILTER: "Filter",
        CONVERTED_FROM: "Converted From",
        EXCHANGE_RATE: "Exchange Rate",
        UNCONVERTED_PRICE: "Unconverted Price",
        NAME: "Name",
        EMAIL: "Email",
        TYPE: "Type",
        DATE_TYPE: "Date Type",
        FOUND: "Found",
        CONTACTS: "Contacts",
        PERSONALIZED_WEEKLY_OFFER_CONTACTS: "PWO Contacts",
        COMPANY: "Company",
        SELECTED: "Selected",
        WEEK: "Week",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        LIST_OF_THE_CONTACTS: "List of the Contacts",
        EXPAND_ALL: "Expand All",
        COLLAPSE_ALL: "Collapse All",
        ADDITIONAL_COLUMNS: "Additional Columns",
        FILTERS: "Filters",
        REFRESH_DATA: "Refresh Data",
        CLOSE: "Close",
        CLOSE_AND_SELECT: "Close and Select",
        STATUS: "Status",
        TEMPERATURE: "Temperature",
        CREATED_BY: "Created By",
        CREATED_DATE: "Created Date",
        PUBLISHED_BY: "Published By",
        PUBLISHED_DATE: "Published Date",
        OPEN: "Open",
        COPY: "Copy",
        PRINT_VIEW: "Print View",
        DOWNLOAD: "Download",
        SHOW_RELATED_CONTACTS: "Show Related Contacts",
        CURRENCY: "Currency",
        COUNTRY: "Country",
        EXPIRATION_DATE: "Expiration Date",
        TRANSPORT_COST: "Transport Cost",
        LAST_UPDATED: "Last Updated",
        ADD_TRANSPORT_DATA: "Add Transport Data",
        SAME_COUNTRIES: "Same Countries",
        CUSTOM_TRANSPORT_COST: "Custom Transport Cost",
        LOADING_COUNTRY: "Loading Country",
        CLIENT_PRICE: "Client Price",
        MISSING_TRANSPORT_COSTS: "Missing Transport Costs",
        DELETE: "Delete",
        CONTACTS_LOADING: "Contacts Loading",
        REFRESH_PRICE_LIST: "Refresh Price List",
        NEW_POSITIONS: "New Positions",
        UPDATED_POSITIONS: "Updated Positions",
        DELETED_POSITIONS: "Deleted Positions",
        NO_CHANGES: "No Changes",
        APPROVE: "Approve",
        COST_BASE: "Cost Base",
        LEAD_OFFER: "Lead Offer",
        OFFER: "Offer",
        INCOTERMS: "Incoterms",
        DESTINATION_COUNTRY: "Destination Country",
        PRICE_LISTS: "Price Lists",
        REQUEST_QUOTE: "Request a quote",
        DATE: "Date",
        SHOW_OLDER: "Show Older",
        MATCH_TYPE: "Match Type",
        PRICE: "Price",
        POSITION_NAME: "Position Name",
        TRANSPORT_PRICE: "Transport Price",
        TOTAL_PRICE: "Total Price",
        PRICE_LIST: "Price List",
        OPPORTUNITY_REQUIREMENTS: "Opportunity Requirements",
        REFRESH: "Refresh",
        OPEN_IN_LIVE_LIST: "Open in Live List",
        IMPORT_TYPE: "Import Type",
        CHOOSE_FILE: "Choose File",
        DOWNLOAD_TEMPLATE: "Download Template",
        PLEASE_CHOOSE_FILE: "Please choose file",
        COPY: "Copy",
      },
      TABLE: {
        OPTIONS_COLUMN: "Options",
        TOTAL_ITEMS: "Total Items",
      }
    },
    TRANSLATOR: {
        SELECT: "Select your language"
    },
    MENU: {
        NEW: "new",
        ACTIONS: "Actions",
        CREATE_POST: "Create New Post",
        PAGES: "Pages",
        FEATURES: "Features",
        APPS: "Apps",
        DASHBOARD: "Dashboard"
    },
    AUTH: {
        GENERAL: {
            WELCOME: "Welcome to Adex VIMS!",
            WELCOME_DESCRIPTION:
                "Virtual Inventory Management System for offer/request solutions",
            FOOTER_LEFT: "Adex",
            OR: "Or",
            SUBMIT_BUTTON: "Submit",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Forgot Password",
            BACK_BUTTON: "Back",
            PRIVACY: "Privacy",
            LEGAL: "Legal",
            CONTACT: "Contact"
        },
        LOGIN: {
            TITLE: "Login Account",
            DESCRIPTION:
                "Please contact the website Administrator to obtain username and password.",
            VALID_EMAIL: "A valid email address is required.",
            VALID_PASSWORD: "Password is required.",
            BUTTON: "Sign In",
            CREDENTIALS_ERROR: "Wrong username or password.",
            GOOGLE_EMAIL_ERROR: "Invalid account.",
            VALID_PASSWORD_CONFIRM: "Passwords must match",
            LOGIN_BUTTON: "Login"
        },
        FORGOT: {
            TITLE: "Forgot Password?",
            DESC: "Success. Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset.",
            BUTTON: "Send",
            SUCCESS_MESSAGE:
                "You have received an email with link to reset your password."
        },
        RESET: {
            TITLE: "Reset Password",
            BUTTON: "Save",
            SUCCESS_MESSAGE: "Success. Please login with your new password."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    PROFILE: {
      PROFILE_BUTTON: "Profile",
      LOG_OUT_BUTTON: "Sign Out",
      INFO_HEADER: "Profile Information",
      DISPLAY_PREFERENCES_HEADER: "Display Preferences",
      NAME_LABEL: "Name",
      EMAIL_LABEL: "Email"
    },
    OFFER: {
        TITLE: "Offer",
        SUB_TITLE: "Add New Offer"
    },
    REQUEST: {
        TITLE: "Request",
        SUB_TITLE: "Add New Request"
    },
    NEW_REQUEST: {
        TITLE: "New Request",
        SUB_TITLE: "Add New Request"
    },
    LIST_OFFERS: {
        TITLE: "List Offers",
        SUB_TITLE: "List All Offers"
    },
    NEW_QUICK_OFFER: {
        TITLE: "Create Quick Offer",
        SUB_TITLE: "New Quick Offer"
    },
    NEW_QUICK_REQUEST: {
        TITLE: "Create Quick Request",
        SUB_TITLE: "New Quick Request"
    },
    UPDATE_REQUEST: {
        TITLE: "Update Request",
        SUB_TITLE: "Update Request {id}"
    },
    LIST_REQUESTS: {
        TITLE: "List Requests",
        SUB_TITLE: "List All Requests"
    },
    USERS: {
        TITLE: "Users",
        SUB_TITLE: "Users",
        LABELS: {
          GOOGLE_LOGIN: "Google Login"
        }
    },
    TEAMS: {
        TITLE: "Teams",
        SUB_TITLE: "Teams",
        NEED_OFFERS_PERRMISSION_WARNING: "Need Offers Permission",
    },
    COMPANY_CONTACTS: {
        TITLE: "Company Contacts",
        SUB_TITLE: "Company Contacts"
    },
    ALIASES: {
        TITLE: "Edit Aliases",
        SUB_TITLE: "Edit Aliases",
        LIST: "Aliases",
        TABLE_COLUMNS: {
          VALUE: "Original",
          FORMATTED_VALUE: "Clean",
          NUMBER_OF_TYPE: "Number of type"
        },
        EMPTY_VALUE_ERROR_MESSAGE: "Please fill all selected values!"
    },
    MAPPINGS: {
        EDIT: "Edit Mapping",
        CREATE: "Create Mapping",
        LIST: "Mappings",
        EDIT_VALUES: "Edit Mapping Values",
        EXPAND_HIDE_ALL: "Expand/Hide All",
        ADD_MAPPING_VALUE: "Add Mapping Value",
        EDIT_MAPPING_VALUE: "Edit Mapping Value",
        VALUE_LABEL: "Value",
        GROUP_VALUE_LABEL: "Group Value",
        GROUP_LABEL: "Group",
        PARENT_LABEL: "Parent",
        DOWNLOAD_BIG_FILE_CONFIRM: "The file is too big and if you try to import it, this may slow down the server.",
        LIVE_LIST_NOT_IMMEDIATELY_APPEARS: "The changes is not immediately appears in the live list. It may take a few seconds.",
    },
    NORMALIZE_DATA: {
        EDIT: "Edit Normalize Data",
        CREATE: "Create Normalize Data",
        LIST: "Normalize Data"
    },
    TRANSPORT_DATA: {
      TITLE: "Transport Data",
      ADD_TRANSPORT_DATA: "Add transport data",
      EDIT_TRANSPORT_DATA: "Edit transport data",
      COUNTRY_A: "Country A",
      COUNTRY_B: "Country B",
      DISTANCE: "Distance",
      PRICE: "Price",
    },
    LIVE_LIST: {
        TITLE: "Live List",
        COST_BASE: "Cost Base",
        WEIGHTED_AVERAGE_PRICE: "Weighted Average Price",
        MIN_PRICE_OF_RANK: "Min Price Of Rank",
        MAX_PRICE: "Max Price",
        APPROVED: "Approved",
        LEAD: "Lead",
        INDICATORS: {
            HAS_NEW_APPROVED_OFFERS: "New Approved Offers",
            HAS_NEW_NOT_APPROVED_OFFERS: "New Not Approved Offers",
            HAS_EXPIRED_OFFERS: "Expired Offers",
            AUTO_APPROVED: "Auto Approved",
        },
        FILTERS: {
            LAST_AUTO_UPDATED: "Last Auto Updated",
            MANNUALY_SET_COST_BASE: "Manually Set Cost Base",
            NEW_OFFERS_THAT_ARE_NOT_APPROVED: "New Offers That Are Not Approved",
            NEW_APPROVED_OFFERS_WITH_MANNUALY_SET_COST_BASE: "New Approved Offers With Manually Set Cost Base",
            NEWLY_ARRIVED_OFFERS: "Newly-arrived Offers",
        }
    },
    PRICE_LISTS: {
      LIST: "Price Lists",
      CREATE: "Create Price List",
      EDIT: "Edit Price List",
      ADD: "Add Price List",
      STEP_1: "Base",
      STEP_2: "Price List",
      STEP_3: "Preview",
      STEP_4: "Publish",
      FOR_GROUP_OF_CONTACTS: "Select Group of Contacts",
      DATA_LOSS_CONFIRM: "This action may cause data loss. Are you sure you want to continue?",
      COST_BASE: "Cost Base",
      TRANSPORT_COST: "Transport Cost",
      CLIENT_PRICE: "Client Price",
      WEIGHTED_AVERAGE_PRICE: "Weighted Average Price",
      MIN_PRICE_OF_RANK: "Min Price Of Rank",
      MAX_PRICE: "Max Price",
      LEAD: "Lead",
      PUBLISH_CONFIRM: "Are you sure you want to publish this price list?",
      PUBLISHED_TYPE: "Published Via",
      PUBLISHED_DATE: "Published Date",
      SHARE_TO_USERS: "Share to Users",
      SHARE_TO_TEAMS: "Share to Teams",
      PUBLISH_BUTTON: "Publish",
      INCLUDE_TRANSPORT_COST: "Transport Cost",
    },
    PRICING: {
        EDIT: "Edit Pricing",
        CREATE: "Create Pricing",
        LIST: "Pricing"
    },
    COPPER_APPS: {
        LOGIN_HEADING: "VIMS Login",
        CLONE_INTERESTS_HEADING: "Clone Interests",
        SHOW_PRICE_LIST_HEADING: "Show Price List",
        CLONE_INTERESTS_BUTTON: "Clone Interests",
        ADD_INTERESTS_BUTTON: "Add Interests",
        OVERWRITE_INTERESTS_BUTTON: "Overwrite Interests",
        CLONE_INTERESTS_OPTIONS_MODAL_TEXT: "Selected contact has interests already, please select what should be done!",
        DIFFERENT_EMAIL_ERROR: "Logging email must be same as Copper logged email",
        ORDER_LABELS: {
          ALPHABETICAL: "Alphabetical",
          INDUSTRY_STANDARD: "Industry Standard",
          SELECTED_ITEMS: "Selected Items",
        }
    }
};
