import ApiService from "@/common/api.service";
const PATH = 'copper-entity';

// action types
export const SYNC_COPPER_ENTITY = "syncCopperEntity";

// mutation types
export const SET_COPPER_ENTITY_LOADING = "setCopperEntityLoading";

const state = {
  copperEntityLoading: false
};

const getters = {
  copperEntityLoading(state) {
    return state.copperEntityLoading;
  }
};

const actions = {
  [SYNC_COPPER_ENTITY](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_COPPER_ENTITY_LOADING, true);

      ApiService.get(
        `${PATH}/sync`,
        `${params.entityTypeSlug}/${params.entityExternalId}`
      ).then(({ data }) => {
        resolve(data);
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        context.commit(SET_COPPER_ENTITY_LOADING, false);
      });
    });
  }
};

const mutations = {
  [SET_COPPER_ENTITY_LOADING](state, value) {
    state.copperEntityLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
