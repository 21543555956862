import store from "@/store";
import router from "@/router";
import { GET_CURRENT_USER, LOGOUT } from "@/store/auth.module";
import CopperContextService from '@/common/copper-context.service';

const checkUserEmail = (email) => {
  return new Promise(async resolve => {
    let loggedCopperEmail = await CopperContextService.getLoggedUserEmail();

    if (email !== loggedCopperEmail) {
      store
        .dispatch(LOGOUT)
        .then(() => {
          router.push({
            name: "copper-embedded-apps-login",
            query: { previousRoute: window.location.pathname + window.location.search }
          });
        }).finally(() => {
          resolve(false);
        });
    } else {
      resolve(true);
    }
  });
};

export default async (to, from, next) => {
	if (!store.getters.currentUser.id) {
		store
			.dispatch(GET_CURRENT_USER)
			.then(async (data) => {
        let isEmailValid = await checkUserEmail(data.email);

        if (isEmailValid) {
          return next();
        }
			});
	} else {
    let isEmailValid = await checkUserEmail(store.getters.currentUser.email);
    if (isEmailValid) {
      return next();
    }
  }
};