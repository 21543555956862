import ApiService from "@/common/api.service";

// action types
export const GET_QUICK_REQUEST = "getQuickRequest";
export const STORE_QUICK_REQUEST = "storeQuickRequest";
export const UPDATE_QUICK_REQUEST = "updateQuickRequest";

// mutation types

const state = {};

const getters = {};

const actions = {
    [GET_QUICK_REQUEST](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("quickRequest", id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [STORE_QUICK_REQUEST](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("quickRequest", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_QUICK_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("quickRequest", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
