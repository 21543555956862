import ApiService from "@/common/api.service";
const PATH = 'countries';

// action types
export const GET_COUNTRIES = "getCountries";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_COUNTRIES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(PATH).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
