import ApiService from "@/common/api.service";
import OfferService from "@/common/offer.service";
import { API_PATH } from "@/common/config";

export const TABLE_INIT_URL = API_PATH + "vendorOffers/table/init";

// action types
export const SEARCH_VENDOR_OFFERS = "searchVendorOffers";
export const STORE_VENDOR_OFFER = "storeVendorOffer";
export const UPDATE_VENDOR_OFFER = "updateVendorOffer";
export const SUGGEST_TAGS = "suggestTags";
export const SEARCH_TAGS_BY_CATEGORY = "searchTagsByCategory";
export const SET_SELECT_CATEGORY_OPTIONS = "setSelectCategoryOptions";
export const GET_VENDOR_OFFER_COUNT = "getVendorOfferCount";
export const GET_VENDOR_OFFER_TABLE_INIT = "getVendorOfferTableInit";
export const RESET_OFFER_DATA = "resetOfferData";
export const SET_FIELD_CHANGE = "fieldChange";

// mutation types
export const SET_VENDOR_OFFERS = "setVendorOffers";
export const SET_SELECT_CATEGORY_OPTION = "setSelectCategoryOption";
export const SET_ERROR = "setError";

const state = {
    errors: {},
    vendorOffers: [],
    selectCategoryOptions: {},
    fieldChange: false,
};

const getters = {
    vendorOffers(state) {
        return state.vendorOffers;
    },
    selectCategoryOptions(state) {
        return state.selectCategoryOptions;
    },
    fieldChange(state) {
        return state.fieldChange;
    },
    selectOptionsByCategory: state => name => {
        return typeof state.selectCategoryOptions[name] !== "undefined"
            ? state.selectCategoryOptions[name]
            : null;
    }
};

const actions = {
    [SEARCH_VENDOR_OFFERS](context, search) {
        return new Promise((resolve, reject) => {
            ApiService.search("vendorOffers", search)
                .then(({ data }) => {
                    context.commit(SET_VENDOR_OFFERS, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [STORE_VENDOR_OFFER](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("vendorOffers", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [GET_VENDOR_OFFER_COUNT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query("vendorOffers/table/data", params)
                .then(({ data }) => {
                    resolve(data.filtered);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [GET_VENDOR_OFFER_TABLE_INIT](context) {
        return new Promise((resolve, reject) => {
            ApiService.query("vendorOffers/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [SUGGEST_TAGS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("vendorOffers/suggest", params)
                .then(({ data }) => {
                    context.dispatch(SET_SELECT_CATEGORY_OPTIONS, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [SEARCH_TAGS_BY_CATEGORY](context, category) {
        return new Promise((resolve, reject) => {
            ApiService.post("vendorOffers/suggest", {
                tags: [
                    {
                        category: category,
                        value: "-"
                    }
                ]
            })
                .then(({ data }) => {
                    let options = data.data[0].options || [];
                    context.commit(SET_SELECT_CATEGORY_OPTION, {
                        category: category,
                        options: options
                    });
                    resolve(options);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response.data);
                });
        });
    },
    [SET_SELECT_CATEGORY_OPTIONS](context, tags) {
        tags.forEach(tag => {
            if (typeof tag.options !== "undefined") {
                context.commit(SET_SELECT_CATEGORY_OPTION, tag);
            }
        });
    },
    [UPDATE_VENDOR_OFFER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("vendorOffers", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [RESET_OFFER_DATA](context, offer) {
        let tagCategories = context.rootState.tagCategory.tagCategories;
        OfferService.resetOfferData(offer, tagCategories);
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_FIELD_CHANGE](state, set) {
        state.fieldChange = set;
    },
    [SET_VENDOR_OFFERS](state, vendorOffers) {
        state.vendorOffers = vendorOffers.data;
        state.errors = {};
    },
    [SET_SELECT_CATEGORY_OPTION](state, optionData) {
        state.selectCategoryOptions[optionData.category] = optionData.options;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
