import ApiService from "@/common/api.service";

// action types
export const GET_QUICK_OFFER = "getQuickOffer";
export const STORE_QUICK_OFFER = "storeQuickOffer";
export const UPDATE_QUICK_OFFER = "updateQuickOffer";

// mutation types

const state = {};

const getters = {};

const actions = {
    [GET_QUICK_OFFER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("quickOffers", id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [STORE_QUICK_OFFER](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("quickOffers", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_QUICK_OFFER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("quickOffers", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
