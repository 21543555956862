import store from "@/store";
import router from "@/router";
import { GET_CURRENT_USER } from "@/store/auth.module";

export default (to, from, next) => {
    if (to.meta.permissions && store.getters.currentUser.permissions) {
        if (
            !to.meta.permissions.length ||
            store.getters.currentUser.permissions.indexOf("super-admin") !==
                -1 ||
            store.getters.currentUser.permissions.filter(
                p => to.meta.permissions.indexOf(p) !== -1
            ).length > 0
        ) {
            next();
        } else {
            router.push({ name: "dashboard" });
        }
    } else if (to.meta.permissions) {
        store
            .dispatch(GET_CURRENT_USER)
            .then(x => {
                if (
                    !to.meta.permissions.length ||
                    x.permissions.indexOf("super-admin") !== -1 ||
                    x.permissions.filter(
                        p => to.meta.permissions.indexOf(p) !== -1
                    ).length > 0
                ) {
                    next();
                } else {
                    router.push({ name: "dashboard" });
                }
            });
    } else {
        next();
    }
};