import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { OauthClient } from "@/common/config";
import i18n from "@/common/plugins/vue-i18n";

// action types
export const LOGIN = "login";
export const GOOGLE_LOGIN = "googleLogin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const REFRESH_TOKEN = "refreshToken";
export const CHECK_TOKEN = "checkToken";
export const GET_USER = "getUser";
export const GET_CURRENT_USER = "getCurrentUser";
export const FORGOT = "forgot";
export const RESET = "reset";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_CURRENT_USER = "setCurrentUser";
export const SET_SUCCESS_MESSAGE = "setSuccessMessage";

export const PASSWORD_GRANT = "password";
export const REFRESH_TOKEN_GRANT = "refresh_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

const state = {
    errors: [],
    successMessage: null,
    auth: {},
    currentUser: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    currentUser(state) {
        return state.currentUser;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            const postData = new OauthClient(PASSWORD_GRANT, credentials);

            ApiService.post("oauth/token", postData)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    if (response.status === 400) {
                        response.data.error_description = i18n.t(
                            "AUTH.LOGIN.CREDENTIALS_ERROR"
                        );
                    }
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [GOOGLE_LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            const postData = new OauthClient(PASSWORD_GRANT, credentials);

            ApiService.post("google-login", postData)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    if (response.status === 400) {
                        response.data.error_description = i18n.t(
                            "AUTH.LOGIN.CREDENTIALS_ERROR"
                        );
                    }
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        return new Promise(resolve => {
            ApiService.post("logout")
                .then(({ data }) => {
                    context.commit(SET_CURRENT_USER, {});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error);
                });
            context.commit(PURGE_AUTH);
        });
    },
    [GET_CURRENT_USER](context) {
        return new Promise((resolve, reject) => {
            ApiService.query("users/currentUser")
                .then(({ data }) => {
                    context.commit(SET_CURRENT_USER, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_USER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("users", id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [REFRESH_TOKEN](context) {
        return new Promise((resolve, reject) => {
            var oauthClientParams = {};
            oauthClientParams[REFRESH_TOKEN_KEY] = JwtService.getRefreshToken();
            const postData = new OauthClient(
                REFRESH_TOKEN_GRANT,
                oauthClientParams
            );

            ApiService.post("oauth/token", postData)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [CHECK_TOKEN](context) {
        return new Promise((resolve, reject) => {
            if (!state.isAuthenticated) {
                reject();
            } else {
                actions[REFRESH_TOKEN](context)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(response => {
                        actions[LOGOUT](context);
                        context.commit(
                            SET_ERROR,
                            response.data.error_description
                        );
                        reject(response);
                    });
            }
        });
    },
    [FORGOT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("forgot", { email: credentials })
                .then(({ data }) => {
                    context.commit(
                        SET_SUCCESS_MESSAGE,
                        i18n.t("AUTH.FORGOT.SUCCESS_MESSAGE")
                    );
                    resolve(data);
                })
                .catch(({ response }) => {

                    context.commit(SET_ERROR, response.data.errors['email']);
                    reject(response);
                });
        });
    },
    [RESET](context, payload) {
        return new Promise((resolve, reject) => {
            const { email, password, password_confirmation, token } = payload;
            const form = { email, password, password_confirmation, token };
            ApiService.post("reset", form)
                .then(({ data }) => {
                    context.commit(
                        SET_SUCCESS_MESSAGE,
                        i18n.t("AUTH.RESET.SUCCESS_MESSAGE")
                    );
                    resolve(data);
                })
                .catch(({ response }) => {
                    if (response.status === 404) {
                        response.data.error_description = response.data.error;
                    }
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, errors) {
        if (Array.isArray(errors)) {
            state.errors = errors;
        } else {
            state.errors = [errors];
        }
    },
    [SET_SUCCESS_MESSAGE](state, message) {
        state.successMessage = message;
    },
    [SET_AUTH](state, auth) {
        state.isAuthenticated = true;
        state.errors = [];
        state.auth = auth;
        JwtService.saveTokens(state.auth);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.auth = {};
        state.errors = [];
        JwtService.destroyTokens();
    },
    [SET_CURRENT_USER](state, user) {
        state.currentUser = user;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
