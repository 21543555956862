import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import { GET_CURRENT_USER } from "@/store/auth.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import enso from "./common/plugins/enso";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import "tiptap-vuetify/dist/main.css";
import { SHOW_SNACK } from "@/store/snack.module";

// API service init
ApiService.init();

// Scroll page to top on every route change
router.beforeEach((to, from, next) => {
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    next();
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    enso,
    render: h => h(App)
}).$mount("#app");

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: "md"
});

Vue.mixin({
    methods: {
        hasPermissions: function(permission) {
            if (!permission) {
                return true;
            }

            if (!store.getters.currentUser || !store.getters.currentUser.id) {
                return false;
            }

            if (
               Array.isArray( store.getters.currentUser.permissions) && store.getters.currentUser.permissions.indexOf("super-admin") !==
                -1
            ) {
                return true;
            }

            const permissions = Array.isArray(permission)
                ? permission
                : [permission];
            return (
                permissions.filter(
                    p => store.getters.currentUser.permissions.indexOf(p) !== -1
                ).length > 0
            );
        },
        isAdmin: function() {
            return (
                store.getters.currentUser.permissions.indexOf("super-admin") !==
                -1
            );
        },
        reloadCurrentUser: function() {
            store.dispatch(GET_CURRENT_USER);
        },
        handleException: function(response) {
            let message = this.$t("GENERAL.UNEXPECTED_ERROR");
            if (response?.message) {
                switch (response.message) {
                    case "Request failed with status code 403":
                        return;
                }
            }
            store.dispatch(SHOW_SNACK, {
                message: message,
                type: "error",
                timeout: -1
            });
        }
    }
});
