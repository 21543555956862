import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";

export const TABLE_INIT_URL = API_PATH + "teams/table/init";

// action types
export const GET_TEAMS_REQUEST = "getTeamsRequest";
export const GET_TEAM_REQUEST = "getTeamRequest";
export const CREATE_TEAM_REQUEST = "createTeamRequest";
export const UPDATE_TEAM_REQUEST = "updateTeamRequest";
export const DELETE_TEAM_REQUEST = "deleteTeamRequest";
export const GET_TEAM_REQUEST_TABLE_INIT = "getTeamRequestTableInit";
export const GET_TEAM_PERMISSIONS_REQUEST = "getTeamPermissionsRequest";

// mutation types

const state = {

};

const getters = {

};

const actions = {
    [GET_TEAM_REQUEST](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get("teams",id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_TEAMS_REQUEST](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("teams")
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [CREATE_TEAM_REQUEST](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("teams", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_TEAM_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("teams", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_TEAM_REQUEST](context) {
        return new Promise((resolve, reject) => {

        });
    },
    [GET_TEAM_REQUEST_TABLE_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query("teams/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_TEAM_PERMISSIONS_REQUEST]() {
        return new Promise((resolve, reject) => {
            ApiService.query("teams/permissions")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
