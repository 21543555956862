import ApiService from "@/common/api.service";
import UuidService from "@/common/uuid.service";
import SelectionService from "@/common/selection.service";
import OfferService from "@/common/offer.service";
import ObjectsService from "@/common/objects.service";
import { defaultsDeep } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";

// action types
export const SEARCH_VENDOR_OFFER_SETS = "searchVendorOfferSets";
export const PROCESS_VENDOR_OFFER_SET = "processVendorOfferSets";
export const STORE_VENDOR_OFFER_SET = "storeVendorOfferSet";
export const SET_TAG_TO_ALL_OFFERS = "setTagToAllOffers";
export const UPDATE_OFFER_TAG = "updateOfferTag";
export const SET_NEW_TAG_CATEGORY_TO_OFFERS = "setNewCategoryToOffers";
export const RESET_DATA = "resetData";
export const EDIT_OFFER = "editVendorOfferSet";
export const UPDATE_VENDOR_OFFER_SET = "updateVendorOfferSet";

// mutation types
export const SET_VENDOR_OFFER_SETS = "setVendorOfferSets";
export const SET_FORMATTED_SET_TEXT = "setFormattedSetText";
export const SET_PROCESSED_SET = "setProcessedSet";
export const SET_VENDOR_OFFER_SET = "setVendorOfferSet";
export const ADD_OFFER_TABLE_ITEM = "addOfferTableItem";
export const REMOVE_OFFER_TABLE_ITEM = "removeOfferTableItem";
export const ADD_PART_OF_NEXT_SET = "addPartOfNextSet";
export const REMOVE_PART_OF_NEXT_SET = "removePartOfNextSet";
export const ADD_PART_OF_ALL = "addPartOfAll";
export const REMOVE_PART_OF_ALL = "removePartOfAll";
export const REFRESH_OFFERS_WITH_PARTS = "refreshOffersWithParts";
export const SET_OFFER_TABLE_ROWS = "setOfferTableRows";
export const SET_PART_OF_NEXT_SET = "setPartOfNextSet";
export const SET_PART_OF_ALL = "setPartOfAll";
export const SET_ERROR = "setError";
export const ADD_OVERWRITE = "addOverwritePart";
export const OVERWRITE_TAG_TO_ALL_OFFERS = "overwriteTagToAllOffers";
export const OVERWRITE_OFFER_TAG = "overwriteOfferTag";



export const SEGMENT_OPTIONS = [
    {
        name: "Part of Next Set",
        color: "teal",
        menuComponent: null,
        addAction: ADD_PART_OF_NEXT_SET,
        removeAction: REMOVE_PART_OF_NEXT_SET
    },
    {
        name: "Part of All",
        color: "light-blue",
        menuComponent: null,
        addAction: ADD_PART_OF_ALL,
        removeAction: REMOVE_PART_OF_ALL
    },
    {
        name: "Offer",
        color: "cyan",
        menuComponent: "OfferMenu",
        addAction: ADD_OFFER_TABLE_ITEM,
        removeAction: REMOVE_OFFER_TABLE_ITEM
    }
];

const state = {
    errors: {},
    vendorOfferSets: [],
    processedSet: {},
    offerTableRows: [],
    vendorOfferSet: {},
    formattedSetText: "",
    partOfNextSet: [],
    partOfAll: []
};

const getters = {
    vendorOfferSets(state) {
        return state.vendorOfferSets;
    },
    processedSet(state) {
        return state.processedSet;
    },
    vendorOfferSet(state) {
        return state.vendorOfferSet;
    },
    formattedSetText(state) {
        return state.formattedSetText;
    },
    offerTableRows(state) {
        return state.offerTableRows;
    },
    partOfNextSet(state) {
        return state.partOfNextSet;
    },
    overwritePart(state) {
        return state.overwritePart;
    },
    partOfAll(state) {
        return state.partOfAll;
    },
    offerByUuid: state => uuid => {
        return state.offerTableRows.find(e => e.uuid === uuid);
    },
    partByUuid: state => uuid => {
        return (
            state.partOfNextSet.find(e => e.uuid === uuid) ||
            state.partOfAll.find(e => e.uuid === uuid)
        );
    }
};

const actions = {
    [SEARCH_VENDOR_OFFER_SETS](context, search) {
        return new Promise((resolve, reject) => {
            ApiService.search("vendorOfferSets", search)
                .then(({ data }) => {
                    context.commit(SET_VENDOR_OFFER_SETS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [PROCESS_VENDOR_OFFER_SET](context, params) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();

            for (var key in params) {
              formData.append(key, params[key]);
            }

            ApiService.post("vendorOfferSets/process", formData)
                .then(({ data }) => {
                    try {
                        params.raw = data.data.set.text;

                        context.commit(SET_VENDOR_OFFER_SET, params);
                        context.commit(SET_PROCESSED_SET, data.data);

                        let segmentedText = data.data.set.text;

                        let orderedEntities = data.data.set.entities.sort(  (a, b) => b.end - a.end  );
                        let segmentOptions = ObjectsService.replaceObjectKeys(SEGMENT_OPTIONS,'name');
                        let tagCategories = context.rootState.tagCategory.tagCategories;
                        let cats = ObjectsService.replaceObjectKeys(tagCategories,'name');

                        orderedEntities.forEach(segment => {
                            let type = segmentOptions[segment.category] || {};
                            let defaults = {};
                            if (type.name !== "Offer") {
                                let partMatch = data.data.parts.find( p => p.text === segment.value );

                                if (partMatch) {
                                    defaults.category = partMatch.entities[0] ? partMatch.entities[0].category : null;
                                    defaults.formatted_value = partMatch.entities[0] ? partMatch.entities[0].formatted_value : null;
                                    defaults.type = segment.category ||'';
                                    defaults.order = segment.start-10000;
                                }

                            } else {
                                let offerMatch = data.data.offers.find( p => p.text === segment.value && !p.isTagsProcessed );
                                defaults = Object.assign( defaults, OfferService.setOfferTags( offerMatch, tagCategories ) );

                            }

                            let uuid = UuidService.generateUuid();

                            let component = SelectionService.getComponentInnerHtml(  { type: type,  secondary: defaults.category ? cats[defaults.category] : null },  segment.value, uuid );

                            segmentedText = SelectionService.replaceRange( segmentedText,  segment.start, segment.end,  component  );

                            defaults = Object.assign(defaults, {   uuid: uuid,  raw: segment.value,  segmented: component,  start: segment.start,  end: segment.end  });

                            if (type.name === "Offer") {
                                defaults = OfferService.createOffer( defaults,  tagCategories );
                            }

                            context.commit(type.addAction, defaults);
                        });
                        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
                        context.getters.offerTableRows.forEach(
                            offerTableRow => {
                                OfferService.setOfferTags(
                                    offerTableRow,
                                    tagCategories
                                );
                            }
                        );
                        context.commit(SET_FORMATTED_SET_TEXT, segmentedText);
                    } catch (error) {
                        console.error(error);
                    }

                    resolve(data);
                })
                .catch(({ response }) => {
                  if (response?.data?.error_description) {
                    context.commit(SET_ERROR, response.data.error_description);
                  }

                    reject(response);
                });
        });
    },
    [STORE_VENDOR_OFFER_SET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("vendorOfferSets", params)
                .then(({ data }) => {
                    context.commit(SET_VENDOR_OFFER_SET, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [ADD_OFFER_TABLE_ITEM](context, payload) {
        let uuid = UuidService.generateUuid();
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );

        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetText,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT, newText);
        let offer = OfferService.createOffer(
            {
                raw: payload.selection.text,
                expanded: payload.selection.text,
                segmented: component,
                start: payload.selection.realStart,
                end: payload.selection.realEnd,
                uuid: uuid
            },
            context.rootState.tagCategory.tagCategories
        );

        context.commit(ADD_OFFER_TABLE_ITEM, offer);
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
    },
    [REMOVE_OFFER_TABLE_ITEM](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetText,
            uuid
        );
        context.commit(SET_FORMATTED_SET_TEXT, newText);
        context.commit(REMOVE_OFFER_TABLE_ITEM, uuid);
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
    },
    [ADD_PART_OF_NEXT_SET](context, payload) {
        let uuid = UuidService.generateUuid();
        var formattedValue =
            payload.data.suggestedTag || payload.selection.text;
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );
        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetText,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT, newText);

        context.commit(ADD_PART_OF_NEXT_SET, {
            raw: payload.selection.text,
            segmented: component,
            start: payload.selection.realStart,
            end: payload.selection.realEnd,
            category: payload.data.secondary.name,
            formatted_value: formattedValue,
            order:payload.selection.realStart-10000,
            type:'Part of Next Set',
            uuid: uuid
        });
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
        context.dispatch(SET_TAG_TO_ALL_OFFERS, {
            value: payload.selection.text,
            formatted_value: formattedValue,
            category: payload.data.secondary.name,
            order:payload.selection.realStart,
            offer:{uuid:''}
        });
    },
    [REMOVE_PART_OF_NEXT_SET](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetText,
            uuid
        );

        context.commit(SET_FORMATTED_SET_TEXT, newText);
        context.commit(REMOVE_PART_OF_NEXT_SET, uuid);
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
    },
    [ADD_PART_OF_ALL](context, payload) {
        let uuid = UuidService.generateUuid();
        var formattedValue =
            payload.data.suggestedTag || payload.selection.text;
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );
        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetText,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT, newText);
        context.commit(ADD_PART_OF_ALL, {
            raw: payload.selection.text,
            segmented: component,
            start: payload.selection.realStart,
            end: payload.selection.realEnd,
            category: payload.data.secondary.name,
            formatted_value: formattedValue,
            order:payload.selection.realStart-10000,
            type:'Part of All',
            uuid: uuid
        });
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
        context.dispatch(SET_TAG_TO_ALL_OFFERS, {
            value: payload.selection.text,
            formatted_value: formattedValue,
            category: payload.data.secondary.name,
            order:payload.selection.realStart,
            offer:{uuid:''}
        });
    },
    [REMOVE_PART_OF_ALL](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetText,
            uuid
        );
        context.commit(SET_FORMATTED_SET_TEXT, newText);
        context.commit(REMOVE_PART_OF_ALL, uuid);
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
    },
    [REFRESH_OFFERS_WITH_PARTS](context) {
        OfferService.refreshOfferParts(
            context.getters.offerTableRows,
            context.getters.partOfNextSet,
            context.getters.partOfAll,
            context.rootState.tagCategory.tagCategories
        );
    },
    [UPDATE_OFFER_TAG](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;
        if(categories.find(x => x.name === payload.category).tag_category_type === 'text'){
            let q = payload.offer[payload.category]['tags'].map(function(o) { if(o.type){ return -1; }else{ return o.order?o.order:0 } });
            payload.order = Math.max.apply(Math, (q.length>0?q:[0]))+1;
            payload.offer[payload.category]['tags'].push(OfferService.setTag(payload));
        }else{
            payload.offer[payload.category]['tags'][0] = OfferService.setTag(payload);
        }

        payload.offer[payload.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(payload.offer[payload.category]['tags']);
        OfferService.setOfferTags(
            payload.offer,
            context.rootState.tagCategory.tagCategories
        );
    },
    [SET_TAG_TO_ALL_OFFERS](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;

        context.getters.offerTableRows.forEach(offer => {
            let selectionIndex = SelectionService.getClosestIndex(
                offer.raw,
                payload.value,
                payload.originalStart || 0
            );

            if(payload.saveType === 'overwrite' && selectionIndex > -1 && payload.offer.uuid !== offer.uuid){
              offer = OfferService.removeOfferTag(offer,categories,selectionIndex);
            }
            if (
                payload.offer.uuid !== offer.uuid &&
                selectionIndex > -1 &&
                OfferService.isIndexFree(offer, categories, selectionIndex)
            ) {

                payload.start = selectionIndex;
                payload.end = selectionIndex + payload.value.length;
                if(categories.find(x => x.name === payload.category).tag_category_type === 'text'){
                    offer[payload.category]['tags'].push(OfferService.setTag(payload));
                }else{
                    offer[payload.category]['tags'][0] = OfferService.setTag(payload);
                }
                offer[payload.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(offer[payload.category]['tags']);

                OfferService.setOfferTags(offer, categories);
            }
        });
    },
    [SET_NEW_TAG_CATEGORY_TO_OFFERS](context, category) {
        context.getters.offerTableRows.forEach(offer => {
            offer[category] = {'formatted_value':'','tags':[{
                uuid: null,
                start: null,
                end: null,
                value: "",
                formatted_value:""
            }]};
        });
    },
    [RESET_DATA](context) {
        context.commit(SET_OFFER_TABLE_ROWS, []);
        context.commit(SET_PART_OF_NEXT_SET, []);
        context.commit(SET_PART_OF_ALL, []);
        let setText = context.getters.processedSet.set
            ? context.getters.processedSet.set.text
            : null;
        context.commit(SET_FORMATTED_SET_TEXT, setText);
    },
    [EDIT_OFFER](context, id) {
        if(!id){
            return;
        }
        context.dispatch(RESET_DATA);
        return new Promise((resolve, reject) => {
            ApiService.getRB("vendorOfferSets/"+id)
                .then(({ data }) => {
                    try {
                        context.commit(SET_VENDOR_OFFER_SET, data.details);
                        context.commit(SET_PROCESSED_SET, data);

                        let segmentedText = data.set.text;

                        let orderedEntities = data.set.entities.sort(  (a, b) => b.end - a.end  );
                        let segmentOptions = ObjectsService.replaceObjectKeys(SEGMENT_OPTIONS,'name');
                        let tagCategories = context.rootState.tagCategory.tagCategories;
                        let cats = ObjectsService.replaceObjectKeys(tagCategories,'name');

                        orderedEntities.forEach(segment => {
                            let type = segmentOptions[segment.category] || {};
                            let defaults = {};
                            if (type.name !== "Offer") {
                                let partMatch = data.parts.find( p => p.text === segment.value );

                                if (partMatch) {
                                    defaults.category = partMatch.entities[0] ? partMatch.entities[0].category : null;
                                    defaults.formatted_value = partMatch.entities[0] ? partMatch.entities[0].formatted_value : null;
                                    defaults.type = segment.category ||'';
                                    defaults.order = segment.order ||segment.start;
                                }

                            } else {

                                let offerMatch = data.offers.find( p => p.text === segment.value && !p.isTagsProcessed );
                                defaults = Object.assign( defaults, OfferService.setOfferTags( offerMatch, tagCategories ) );

                            }

                            let uuid = UuidService.generateUuid();

                            let component = SelectionService.getComponentInnerHtml(  { type: type,  secondary: defaults.category ? cats[defaults.category] : null },  segment.value, uuid );

                            segmentedText = SelectionService.replaceRange( segmentedText,  segment.start, segment.end,  component  );

                            defaults = Object.assign(defaults, {   uuid: uuid,  raw: segment.value,  segmented: component,  start: segment.start,  end: segment.end  });

                            if (type.name === "Offer") {
                                defaults = OfferService.createOffer( defaults,  tagCategories );
                            }

                            context.commit(type.addAction, defaults);
                        });
                        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
                        context.getters.offerTableRows.forEach(
                            offerTableRow => {
                                OfferService.setOfferTags(
                                    offerTableRow,
                                    tagCategories
                                );
                            }
                        );
                        context.commit(SET_FORMATTED_SET_TEXT, segmentedText);
                    } catch (error) {
                        console.error(error);
                    }

                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_VENDOR_OFFER_SET](context, params) {

        return new Promise((resolve, reject) => {
            ApiService.put("vendorOfferSets/"+params.id, params.params)
                .then(({ data }) => {
                    context.commit(SET_VENDOR_OFFER_SET, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    if(response){
                        context.commit(SET_ERROR, response.data.error_description);
                    }
                    reject(response);
                });
        });
    },
    [ADD_OVERWRITE](context, payload) {
        var formattedValue = payload.formatted_value;
        context.dispatch(REFRESH_OFFERS_WITH_PARTS);
        context.dispatch(OVERWRITE_TAG_TO_ALL_OFFERS, {
            value: payload.value,
            formatted_value: formattedValue,
            category: payload.category,
            order:0,
            type:payload.type,
            offer:payload.offer,
            onlyEmpty:payload.onlyEmpty,
        });
    },

    [OVERWRITE_TAG_TO_ALL_OFFERS](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;

        let tag = payload;
        const type = payload.type;
        context.getters.offerTableRows.some(offer => {

            if(tag.onlyEmpty && offer[tag.category]['formatted_value'] && offer.uuid !== tag.offer.uuid){
                return false;
            }
            let selectionIndex = SelectionService.getClosestIndex(
                offer.raw,
                tag.value,
                tag.originalStart || 0
            );
            tag.uuid = UuidService.generateUuid();

            let isFree = selectionIndex > -1 ? OfferService.isIndexFree(offer, categories.filter(x => x.name !== tag.category), selectionIndex):true;

            tag.start = selectionIndex > -1 && isFree?selectionIndex:null;
            tag.end = selectionIndex > -1 && isFree?selectionIndex + tag.value.length:null;
            tag.order = selectionIndex > -1?null:(selectionIndex - 10000);
            tag.type = selectionIndex > -1?'':type;
            offer[tag.category]['tags'] = [];
            offer[tag.category]['tags'][0] = OfferService.setTag(tag);
            offer[tag.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(offer[tag.category]['tags']);

            OfferService.setOfferTags(offer,categories);

        });
    },
    [OVERWRITE_OFFER_TAG](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;
        let offer = payload.offer;
        offer[payload.category]['tags'].map(tag => {
            if(!tag.start){
                let selectionIndex = SelectionService.getClosestIndex(
                    offer.raw,
                    tag.value,
                    0
                );
                let isFree = selectionIndex > -1 ? OfferService.isIndexFree(offer, categories.filter(x => x.name !== tag.category), selectionIndex):true;
                tag.uuid = UuidService.generateUuid();
                tag.start = selectionIndex > -1 && isFree?selectionIndex:null;
                tag.end = selectionIndex > -1 && isFree?selectionIndex + tag.value.length:null;
            }
        })

        OfferService.setOfferTags(offer,categories);
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_VENDOR_OFFER_SETS](state, vendorOfferSets) {
        state.vendorOfferSets = vendorOfferSets.data;
    },
    [SET_PROCESSED_SET](state, processedSet) {
        state.processedSet = processedSet;
    },
    [SET_VENDOR_OFFER_SET](state, vendorOfferSet) {
        state.vendorOfferSet = vendorOfferSet;
    },
    [SET_OFFER_TABLE_ROWS](state, offerTableRows) {
        state.offerTableRows = offerTableRows;
    },
    [SET_PART_OF_NEXT_SET](state, partOfNextSet) {
        state.partOfNextSet = partOfNextSet;
    },
    [SET_PART_OF_ALL](state, partOfAll) {
        state.partOfAll = partOfAll;
    },
    [SET_FORMATTED_SET_TEXT](state, formattedSetText) {
        state.formattedSetText = formattedSetText;
    },
    [ADD_OFFER_TABLE_ITEM](state, offer) {
        state.offerTableRows.push(offer);
    },
    [REMOVE_OFFER_TABLE_ITEM](state, uuid) {
        let index = state.offerTableRows.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.offerTableRows.splice(index, 1);
        }
    },
    [ADD_PART_OF_NEXT_SET](state, item) {
        state.partOfNextSet.push(item);
    },
    [REMOVE_PART_OF_NEXT_SET](state, uuid) {
        let index = state.partOfNextSet.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.partOfNextSet.splice(index, 1);
        }
    },
    [ADD_PART_OF_ALL](state, item) {
        state.partOfAll.push(item);
    },
    [REMOVE_PART_OF_ALL](state, uuid) {
        let index = state.partOfAll.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.partOfAll.splice(index, 1);
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
