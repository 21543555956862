import ApiService from "@/common/api.service";

// action types
export const GET_TAG_CATEGORY_USES = "gettagCategoryUses";

// mutation types
export const SET_TAG_CATEGORY_USES = "settagCategoryUses";
export const SET_ERROR = "setError";

const state = {
    errors: {},
    tagCategoryUses: []
};

const getters = {
    tagCategoryUses(state) {
        return state.tagCategoryUses;
    }
};

const actions = {
    [GET_TAG_CATEGORY_USES](context) {
        return new Promise(resolve => {
            ApiService.get("tagCategoryUses")
                .then(({ data }) => {
                    context.commit(SET_TAG_CATEGORY_USES, data);
                    resolve(data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TAG_CATEGORY_USES](state, tagCategoryUses) {
        state.tagCategoryUses = tagCategoryUses.data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
