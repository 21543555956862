import ApiService from "@/common/api.service";
const PATH = 'interests';

// action types
export const UPDATE_CLIENT_INTERESTS = "updateClientInterests";
export const GET_ENTITY_HAS_INTERESTS = "getEntityHasInterests";
export const ADD_CLIENT_INTERESTS = "addClientInterests";
export const OVERWRITE_CLIENT_INTERESTS = "overwriteClientInterests";

// mutation types
export const SET_CLIENT_INTERESTS_LOADING = "setClientInterestsLoading";

const state = {
  clientInterestsLoading: false
};

const getters = {
  clientInterestsLoading(state) {
    return state.clientInterestsLoading;
  }
};

const actions = {
  [UPDATE_CLIENT_INTERESTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/update`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [GET_ENTITY_HAS_INTERESTS](context, params) {
    return new Promise((resolve, reject) => {

      ApiService.get(
        `${PATH}/entity-has-interests`,
        `${params.entityTypeSlug}/${params.entityExternalId}`
      ).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [ADD_CLIENT_INTERESTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/add`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}/${payload.relatedEntityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [OVERWRITE_CLIENT_INTERESTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/overwrite`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}/${payload.relatedEntityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  }
};

const mutations = {
  [SET_CLIENT_INTERESTS_LOADING](state, value) {
    state.clientInterestsLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
