import ApiService from "@/common/api.service";

// action types
export const GET_VALIDATION_RULES = "getValidationRules";

// mutation types
export const SET_VALIDATION_RULES = "setValidationRules";
export const SET_ERROR = "setError";

const state = {
    errors: {},
    validationRules: []
};

const getters = {
    validationRules(state) {
        return state.validationRules;
    }
};

const actions = {
    [GET_VALIDATION_RULES](context) {
        return new Promise(resolve => {
            ApiService.get("validationRules")
                .then(({ data }) => {
                    context.commit(SET_VALIDATION_RULES, data);
                    resolve(data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_VALIDATION_RULES](state, validationRules) {
        state.validationRules = validationRules.data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
