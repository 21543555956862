import ApiService from "@/common/api.service";
const PATH = 'interest-fields';

// action types
export const GET_CLIENT_INTERESTS_FIELDS = "getClientInterestsFields";
export const GET_CLIENT_INTERESTS_GRPOUPED_FIELD_VALUES = "getClientInterestsGroupedFieldValues";

// mutation types
export const SET_CLIENT_INTEREST_FIELDS_LOADING = "setClientInterestFieldsLoading";

const state = {
  clientInterestFieldsLoading: false
};

const getters = {
  clientInterestFieldsLoading(state) {
    return state.clientInterestFieldsLoading;
  }
};

const actions = {
  [GET_CLIENT_INTERESTS_FIELDS](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_CLIENT_INTEREST_FIELDS_LOADING, true);

      ApiService.get(
        `${PATH}/get`,
        `${params.entityTypeSlug}/${params.entityExternalId}`
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      })
      .finally(() => {
        context.commit(SET_CLIENT_INTEREST_FIELDS_LOADING, false);
      });
    });
  },

  [GET_CLIENT_INTERESTS_GRPOUPED_FIELD_VALUES](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_CLIENT_INTEREST_FIELDS_LOADING, true);

      ApiService.get(
        `${PATH}/get-grouped-mapping-field-values`,
        `${params.fieldId}/${params.entityTypeSlug}/${params.entityExternalId}`
      ).then(({ data }) => {
        context.commit(SET_CLIENT_INTEREST_FIELDS_LOADING, false);

        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  }
};

const mutations = {
  [SET_CLIENT_INTEREST_FIELDS_LOADING](state, value) {
    state.clientInterestFieldsLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
