import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";

export const TABLE_INIT_URL = API_PATH + "users/table/init";

// action types
export const SET_USERS = "setUsers";
export const SEARCH_USER = "searchUserRequest";
export const GET_USERS = "getUsersRequest";
export const GET_USER_REQUEST = "getUserRequest";
export const CREATE_USER_REQUEST = "createUserRequest";
export const UPDATE_USER_REQUEST = "updateUserRequest";
export const DELETE_USER_REQUEST = "deleteUserRequest";
export const GET_USERS_INIT = "getUserRequestTableInit";
export const GET_USERS_TEAMS_REQUEST = "getUserUsersRequest";
export const SET_ERROR = "setError";

// mutation types

const state = {
    errors: {},
    roles: [{id:1,name:"Team Manager"}, {id:0,name:"Team Member"}],
    users: [],
};

const getters = {
    roles(state) {
        return state.roles;
    },
    users(state) {
        return state.users;
    },
};

const actions = {
    [SEARCH_USER](context,search) {
        return new Promise(resolve => {
            ApiService.search("users", search)
                .then(({ data }) => {
                    context.commit(SET_USERS, data.data);
                    resolve(data.data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [GET_USER_REQUEST](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get("users",id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_USERS](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("users")
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [CREATE_USER_REQUEST](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("users", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_USER_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("users", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_USER_REQUEST](context) {
        return new Promise((resolve, reject) => {

        });
    },
    [GET_USERS_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query("users/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_USERS_TEAMS_REQUEST](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get("users/user-manager-teams",id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
};

const mutations = {
    [SET_USERS](state, users) {
        state.users = users;
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
