import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";
import { GET_MAPPING_GROUPED } from "@/store/mappings.module";

const PATH = 'pricing';
export const TABLE_INIT_URL = API_PATH + PATH+"/table/init";

// action types
export const GET_TAG_PRICING = "getTagPricing";
export const GET_PRICINGS = "getPricings";
export const GET_PRICING = "getPricing";
export const GET_PRICING_TYPES = "getPricingTypes";
export const GET_GROUPED_DETAILS = "getPricingDetails";
export const CREATE_PRICING = "createPricing";
export const UPDATE_PRICING = "updatePricing";
export const UPDATE_ACTIVE_PRICE = "updatePricingActive";
export const DELETE_PRICING = "deletePricing";
export const GET_PRICING_INIT = "getPricingTableInit";
export const PRICING_EXPORT_RAW = "exportRawPricingOffers";
export const SET_ERROR = "setError";

// mutation types

const state = {
    errors: {}
};

const getters = {

};

const actions = {

    [GET_PRICINGS](context) {
        return new Promise((resolve, reject) => {
            ApiService.search(PATH)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_PRICING_TYPES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH+'/type')
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [GET_PRICING](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_GROUPED_DETAILS](context,params) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH+'/grouped/details',params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [CREATE_PRICING](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH,params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_PRICING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH, payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_ACTIVE_PRICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH+'/update-active', payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_PRICING](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(PATH,id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_PRICING_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH+"/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_TAG_PRICING](context, params) {
        return new Promise((resolve, reject) => {
           context
                .dispatch(GET_MAPPING_GROUPED, [params.tag_id, params.type_id])
                .then(mapping => {
                   context
                        .dispatch(GET_GROUPED_DETAILS, { data: params })
                        .then(response => {
                            let data = [];
                            let rowId = 0;
                            mapping.forEach((x, i) => {
                                let el =
                                    response.details[x.group + "_" + x.value] ||
                                    {'M-Packaging':'','M-Loading':''};
                                el.group = x.group;
                                el.value = x.value;
                                el.uuid = x.uuid;
                                let rows = params.details.filter(
                                    d => d.uuid == x.uuid
                                );
                                if (rows.length) {
                                    rows.forEach((r, k) => {
                                        el = { ...el, ...r.params };
                                        el.row_id = rowId++;
                                        el.isModified = true;
                                        el.client_price = r.params.price;
                                        params.prepared[el.row_id] = {
                                            details: [],
                                            ids: r.ids,
                                            main_id: r.main_id,
                                            info: {
                                                numbers: r.params.ids.length,
                                                client_price: r.params.price,
                                                average_price:
                                                    r.params.average_price,
                                                sum: r.params.total_kg
                                            },
                                            filters: r.filters,
                                            params: r.params,
                                            selected: {}
                                        };
                                        data.push(el);
                                    });
                                } else {
                                    el.row_id = rowId++;
                                    data.push(el);
                                }
                            });
                            resolve({
                                prepared: params.prepared,
                                details: {
                                    details: data,
                                    columns: response.columns,
                                    loadTags: true
                                }
                            });
                        });
                });
        });
    },
    [PRICING_EXPORT_RAW](context,params) {
        return new Promise((resolve, reject) => {
            ApiService.query('vendorOffers/table/excel',params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
