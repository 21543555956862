import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";

export const TABLE_INIT_URL = API_PATH + "companyContacts/table/init";

// action types
export const GET_COMPANY_CONTACTS_INIT = "getCompanyContactsInit";
export const GET_COMPANY_CONTACT = "getCompanyContact";
export const SEARCH_COMPANY_CONTACTS = "searchCompanyContacts";
export const CONTACT_STORE_EVENT = "storeEvent";
export const CONTACT_SHOW_LISTENER = "showListener";
export const GET_LATEST_SYNC_CONTACT = "getLatestSyncContact";
export const TEST = "test";

// mutation types
export const SET_COMPANY_CONTACTS = "setCompanyContacts";
export const SET_STORE_EVENT_ID = "setStoreEventId";
export const ADD_COMPANY_CONTACT = "addCompanyContact";
export const SET_ERROR = "setError";

const state = {
    errors: {},
    companyContacts: [],
    storeEventId: null
};

const getters = {
    companyContacts(state) {
        return state.companyContacts;
    },
    storeEventId(state) {
        return state.storeEventId;
    },
    newCompanyContact(state) {
        return state.newCompanyContact;
    }
};

const actions = {
    [SEARCH_COMPANY_CONTACTS](context, search) {
        return new Promise(resolve => {
            ApiService.search("companyContacts", search)
                .then(({ data }) => {
                    context.commit(SET_COMPANY_CONTACTS, data.data);
                    resolve(data.data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [GET_COMPANY_CONTACT](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get("companyContacts",id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [TEST](context, search) {
        return new Promise(resolve => {
            ApiService.search("companyContacts", search)
                .then(({ data }) => {
                    context.commit(SET_COMPANY_CONTACTS, data.data);
                    resolve(data.data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [CONTACT_STORE_EVENT](context) {
        if (context.getters.storeEventId) {
            return null;
        }
        return new Promise((resolve, reject) => {
            ApiService.post("companyContacts/storeEvent", {})
                .then(({ data }) => {
                    context.commit(
                        SET_STORE_EVENT_ID,
                        data.data.subscription_id
                    );
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [CONTACT_SHOW_LISTENER](context) {
        if (!context.getters.storeEventId) {
            return null;
        }
        return new Promise((resolve, reject) => {
            ApiService.post("companyContacts/showListener", {
                subscription_id: context.getters.storeEventId
            })
                .then(({ data }) => {
                    if (data.data && data.data.id) {
                        context.commit(ADD_COMPANY_CONTACT, data.data);
                        context.commit(SET_STORE_EVENT_ID, null);
                    }
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [GET_LATEST_SYNC_CONTACT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("companyContacts/getLatestSyncContact")
                .then(({ data }) => {
                    if (data.data && data.data.id) {
                        context.commit(ADD_COMPANY_CONTACT, data.data);
                    }
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [GET_COMPANY_CONTACTS_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query("companyContacts/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_COMPANY_CONTACTS](state, companyContacts) {
        state.companyContacts = companyContacts;
        state.errors = {};
    },
    [ADD_COMPANY_CONTACT](state, companyContact) {
        state.companyContacts.push(companyContact);
        state.errors = {};
    },
    [SET_STORE_EVENT_ID](state, id) {
        state.storeEventId = id;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
