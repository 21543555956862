import UuidService from "@/common/uuid.service";
import SelectionService from "@/common/selection.service";
import ObjectsService from "@/common/objects.service";

export default {
    setOfferTags(offer, categories) {
        let entities = [];
        if(!offer){
            return;
        }
        if (offer.entities) {
            entities = offer.entities;
            offer.raw = offer.text;
        } else {
            categories.forEach(cat => {
                let tags = _.get(offer,cat.name+'.tags',[]);
                offer[cat.name] = {'formatted_value':'','tags':[]};
                if(cat.tag_category_type === 'text'){
                    tags.forEach(t=>{
                        if ( t.start >= 0 && t.end > 0 ) {
                                t.category = cat.name;
                                entities.push(t);
                        }else if(t.value){
                            offer[cat.name]['tags'].push(t);
                        }
                    })
                }else{
                    let t = _.get(tags,'0',{});
                    if (t.start >= 0 && t.end > 0 ) {
                            t.category = cat.name;
                            entities.push(t);
                    }else if(t.value){
                        offer[cat.name]['tags'][0] = t;
                        offer[cat.name]['formatted_value'] = t.formatted_value;
                    }
                }
            });

        }
        let orderedOfferEntities = entities.sort((a, b) => b.end - a.end);
        var taggedText = offer.raw;

        var prevTag = {};
        let cats = ObjectsService.replaceObjectKeys(categories,'name');

        orderedOfferEntities.forEach(function(tag) {
            let catType = cats[tag.category] || {};

            offer[catType.name] = _.get(offer,catType.name,{'formatted_value':'','tags':[]});

            if (prevTag && prevTag.start < tag.end) {
                offer[catType.name] = {'formatted_value':'','tags':[{
                    uuid: null,
                    start: null,
                    end: null,
                    value: null,
                    formatted_value: null
                }]};
                return;
            }

            let tagUuid = tag.uuid || UuidService.generateUuid();
            let tagComponent = SelectionService.getComponentInnerHtml(  { type: catType }, tag.value, tagUuid );

            taggedText = SelectionService.replaceRange(
                taggedText,
                tag.start,
                tag.end,
                tagComponent
            );

            if(catType.tag_category_type !== 'text'){
                offer[catType.name]['formatted_value'] = (tag.formatted_value || '');
            }
            offer[catType.name]['tags'].push({
                uuid: tagUuid,
                start: tag.start,
                end: tag.end,
                value: tag.value,
                order: tag.order?tag.order:0,
                type: tag.type,
                is_new: tag.is_new,
                formatted_value: tag.formatted_value
            });

            prevTag = tag;
        });
        offer = this.orderCategoriesFormattedValue(offer,categories);
        offer.expanded = taggedText;
        offer.isTagsProcessed = true;
        return offer;
    },
    createOffer(defaults, categories) {
        let newOffer = {
            id:defaults.id || null,
            uuid: defaults.uuid || UuidService.generateUuid(),
            expanded: defaults.expanded || null,
            status: defaults.status || null,
            raw: defaults.raw,
            start: defaults.start,
            end: defaults.end,
            setParts: defaults.setParts || [],
            allParts: defaults.allParts || [],
            getPartsContent() {
                let parts = [
                    this.allParts.map(e => e.segmented).join(" "),
                    this.setParts.map(e => e.segmented).join(" ")
                ];

                return parts.join(" ");
            }
        };

        categories.forEach(cat => {
            if (cat.id) {
                let def = defaults[cat.name] || {};
                newOffer[cat.name] = def.hasOwnProperty('tags') ? def : {'formatted_value':'','tags':[this.setTag({})]};
            }
        });

        newOffer = this.orderCategoriesFormattedValue(newOffer,categories);

        return newOffer;
    },
    setTag(el) {
        return {
            value: el.value || "",
            start: typeof el.start !== "undefined" ? el.start : null,
            end: el.end || null,
            uuid: el.uuid || UuidService.generateUuid(),
            formatted_value: el.formatted_value,
            type: el.type || '',
            order: el.order || null
        }
    },
    orderCategoriesFormattedValue(offer,categories){
        categories.forEach(cat => {
            if (cat.id && cat.tag_category_type === 'text' && offer[cat.name] && Array.isArray(offer[cat.name]['tags'])) {
                offer[cat.name].formatted_value = this.orderCategoryFormattedValue(offer[cat.name]['tags']);
            }
        });
        return offer;
    },
    orderCategoryFormattedValue(tags){
        let formattedValue = [];
        tags.sort((a, b) => {
            return (a.order - b.order);
        }).forEach(x => x.type?formattedValue.push(x.formatted_value):null)
        tags.sort((a, b) => {
            return (a.start - b.start);
        }).forEach(x => !x.type?formattedValue.push(x.formatted_value):null)
        return formattedValue.join('|')
    },

    //Refreshes all "Part of all" and "Part of next" columns
    refreshOfferParts(offers, partsOfNext, partsOfAll,categories) {
        let offersByPosition = offers.sort((a, b) => a.end - b.end);
        let partsByPosition = partsOfNext.sort((a, b) => a.end - b.end);
        let cats = {};
        categories.forEach(x => cats[x.name] = x);

        offersByPosition.forEach((offer, offerIndex) => {

            //Remove old part of next set and part of all
            categories.forEach(category => {
                offer[category.name]['tags'] = offer[category.name]['tags'].filter(x => x.type !== 'Part of Next Set' && x.type !== 'Part of All');
                offer[category.name].formatted_value = this.orderCategoryFormattedValue(offer[category.name]['tags']);
            });

            let previousOffer = offersByPosition[offerIndex - 1];
            offer.setParts = [];
            partsByPosition.forEach(partByPosition => {
                if (  offer.start > partByPosition.start && (!previousOffer || partByPosition.start > previousOffer.start) ) {
                    offer.setParts.push(partByPosition);
                }
            });
            if (offer.setParts.length === 0 && previousOffer) {
                offer.setParts = previousOffer.setParts;
            }


            // Set category values
            offer.setParts.some(partByPosition => {
                if(!partByPosition.category){
                    return true;
                }

                if(cats[partByPosition.category].tag_category_type === 'text'){
                    offer[partByPosition.category]['tags'].push(this.setTag({start:null,end:null,value:partByPosition.raw,formatted_value:partByPosition.formatted_value,type:'Part of Next Set',order:partByPosition.order || null}));
                    offer[partByPosition.category].formatted_value = this.orderCategoryFormattedValue(offer[partByPosition.category]['tags']);
                }else{
                    if(offer[partByPosition.category]['tags'].length === 0){
                        offer[partByPosition.category]['tags'].push(this.setTag({}));
                    }
                    if(!['Overwrite', 'Edited'].includes(offer[partByPosition.category]['tags'][0]['type'])){
                        offer[partByPosition.category]['tags'][0].start = null;
                        offer[partByPosition.category]['tags'][0].end = null;
                        offer[partByPosition.category]['tags'][0].value = partByPosition.raw;
                        offer[partByPosition.category]['tags'][0].type = 'Part of Next Set';
                        offer[partByPosition.category]['tags'][0].formatted_value = partByPosition.formatted_value;
                        offer[partByPosition.category].formatted_value = partByPosition.formatted_value;
                    }
                }
            });

            offer.allParts = partsOfAll;
            // Set category values
            offer.allParts.some(partOfAll => {
                if(!partOfAll.category){
                    return true;
                }
                if(cats[partOfAll.category].tag_category_type === 'text'){
                    offer[partOfAll.category]['tags'].push(this.setTag({start:null,end:null,value:partOfAll.raw,formatted_value:partOfAll.formatted_value,type:'Part of All',order:partOfAll.order || null}));
                    offer[partOfAll.category].formatted_value = this.orderCategoryFormattedValue(offer[partOfAll.category]['tags']);
                }else {
                    if(offer[partOfAll.category]['tags'].length === 0){
                        offer[partOfAll.category]['tags'].push(this.setTag({}));
                    }
                    if(!['Overwrite', 'Edited'].includes(offer[partOfAll.category]['tags'][0]['type'])){
                        offer[partOfAll.category]['tags'][0].start = null;
                        offer[partOfAll.category]['tags'][0].end = null;
                        offer[partOfAll.category]['tags'][0].value = partOfAll.raw;
                        offer[partOfAll.category]['tags'][0].type = 'Part of All';
                        offer[partOfAll.category]['tags'][0].formatted_value = partOfAll.formatted_value;
                        offer[partOfAll.category].formatted_value = partOfAll.formatted_value;
                    }
                }

            });
        });
    },
    resetOfferData(offer, categories) {
        categories.forEach(cat => {
            Object.keys(offer[cat.name]).forEach(key => {
                offer[cat.name]['formatted_value'] = '';
                offer[cat.name]['tags'] = [];
            });
        });
        offer.expanded = offer.raw;
    },
    isIndexFree(offer, categories, index) {
        let isFree = true;

        for (let cat of categories) {
            let tags = _.get(offer,cat.name+'.tags',[]);

            for (let tag of tags) {
                const start = tag.start || -1;
                const end = tag.end || -1;
                if (index >= start && index <= end) {
                    isFree = false;
                    break;
                }

            }

        }

        return isFree;
    },
    removeOfferTag(offer, categories, index) {

        for (let cat of categories) {
            let tags = _.get(offer,cat.name+'.tags',[]);

            offer[cat.name]['tags'] = tags.filter(x => (index >= (x.start || -1) && index <= (x.end || -1))?false:true);
            if(offer[cat.name]['tags'].length === 0){
                offer[cat.name]['tags'].push(this.setTag({}));
            }
        }

        return offer;
    }
};
