export const API_URL = "https://vims-app.adextp.com";
export const API_PATH = "/api/v1/";

export var OauthClient = (grant, params) => {
    var clientData = {
        grant_type: grant,
        client_id: 2,
        client_secret: "21tTtkabnOlBtdMza0Kn54WMNbHlE944LPuuyaPP",
        scope: "*"
    };

    if (params && typeof params === "object") {
        clientData = Object.assign(clientData, params);
    }

    return clientData;
};

export const COPPER_NEW_CONTACT =
    "https://app.prosperworks.com/companies/160998/app?p=db#/contacts/default";
export const GOOGLE_CLIENT_ID = "1090380857627-g8mepfl63aj3gvgmi0hk1um9lnj8ia2b.apps.googleusercontent.com"

export default API_URL;
