import ApiService from "@/common/api.service";
const PATH = 'offer-groups';

// action types
export const GET_OFFER_GROUPS = "getOfferGroups";
export const GET_ENTITY_OFFER_GROUP = "getEntityOfferGroup";
export const UPDATE_ENTITY_OFFER_GROUP = "updateEntityOfferGroup";

// mutation types
export const SET_OFFER_GROUPS_LOADING = "setClientInterestsLoading";

const state = {
  offerGroupsLoading: false
};

const getters = {
  offerGroupsLoading(state) {
    return state.offerGroupsLoading;
  }
};

const actions = {
  [GET_OFFER_GROUPS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `${PATH}/index`
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [GET_ENTITY_OFFER_GROUP](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(SET_OFFER_GROUPS_LOADING, true);

      ApiService.get(
        `${PATH}/get-entity-offer-group`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      })
      .finally(() => {
        context.commit(SET_OFFER_GROUPS_LOADING, false);
      });
    });
  },

  [UPDATE_ENTITY_OFFER_GROUP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/update-entity-offer-group`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  }
};

const mutations = {
  [SET_OFFER_GROUPS_LOADING](state, value) {
    state.offerGroupsLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
