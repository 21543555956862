import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";
const PATH = 'aliases';
export const TABLE_INIT_URL = API_PATH + PATH+"/table/init";

// action types
export const GET_ALIAS = "getAlias";
export const GET_ALIAS_BY_TYPE = "getAliasByType";
export const GET_ALIAS_DETAILS = "getAliasDetails";
export const UPDATE_ALIAS_DETAILS = "updateAliasDetails";
export const DELETE_ALIAS_DETAIL = "deleteAliasDetail";
export const GET_ALIASES_INIT = "getAliasTableInit";
export const SET_ERROR = "setError";

// mutation types

const state = {
    errors: {}
};

const getters = {

};

const actions = {

    [GET_ALIAS](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_ALIAS_BY_TYPE](context,params) {
        return new Promise((resolve, reject) => {
            ApiService.getType(PATH,params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_ALIAS_DETAILS](context,params) {
      let id = params.id;
      params.id = undefined;

      return new Promise((resolve, reject) => {
          ApiService.query(PATH + '/' + id + '/show-details', params)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [UPDATE_ALIAS_DETAILS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH + '/details', payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [DELETE_ALIAS_DETAIL](context, id) {
      return new Promise((resolve, reject) => {
          ApiService.delete(PATH + '/' + id)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
  },

    [GET_ALIASES_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH+"/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
