import ApiService from "@/common/api.service";
import { SET_CURRENT_USER } from "@/store/auth.module";
const PATH = 'display-preferences';

// action types
export const GET_DISPLAY_PREFERENCES = "getDisplayPreferences";
export const UPDATE_USER_DISPLAY_PREFERENCE = "updateUserDisplayPreference";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_DISPLAY_PREFERENCES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH, params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_USER_DISPLAY_PREFERENCE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH, 'update-user-display-preference', params)
        .then(({ data }) => {
          context.commit(SET_CURRENT_USER, data.data);

          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
