import ApiService from "@/common/api.service";
const PATH = 'transport-data';

// action types
export const GET_TRANSPORT_DATAS = "getTransportDatas";
export const STORE_TRANSPORT_DATA = "storeTransportData";
export const UPDATE_TRANSPORT_DATA = "updateTransportData";
export const DELETE_TRANSPORT_DATA = "deleteTransportData";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_TRANSPORT_DATAS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [STORE_TRANSPORT_DATA](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(PATH, params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_TRANSPORT_DATA](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH, params.id, params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [DELETE_TRANSPORT_DATA](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${PATH}/${id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
