import ApiService from "@/common/api.service";
const PATH = 'live-list';

// action types
export const GET_LIVE_LIST_POSITIONS_TABLE_COLUMNS = "getLiveListPositionsTableColumns";
export const GET_LIVE_LIST_POSITIONS_TABLE_FILTERS = "getLiveListPositionsTableFilters";
export const GET_LIVE_LIST_POSITIONS = "getLiveListPositions";
export const GET_LIVE_LIST_POSITION_OFFERS_TABLE_COLUMNS = "getLiveListPositionOffersTableColumns";
export const GET_LIVE_LIST_POSITION_OFFERS_TABLE_FILTERS = "getLiveListPositionOffersTableFilters";
export const GET_LIVE_LIST_POSITION_OFFERS = "getLiveListPositionOffers";
export const GET_LIVE_LIST_POSITION = "getLiveListPosition";
export const UPDATE_LIVE_LIST_POSITION_LAST_VIEW_DATE = "updateLiveListPositionLastViewDate";
export const UPDATE_LIVE_LIST_POSITION_COST_BASE = "updateLiveListPositionCostBase";
export const REFRESH_LIVE_LIST_POSITION_COST_BASE = "refreshLiveListPositionCostBase";
export const UPDATE_LIVE_LIST_POSITION_LEAD_OFFER = "updateLiveListPositionLeadOffer";
export const UPDATE_LIVE_LIST_POSITION_APPROVED = "updateLiveListPositionApproved";
export const UPDATE_LIVE_LIST_POSITION_ALL_APPROVED = "updateLiveListPositionAllApproved";


const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_LIVE_LIST_POSITIONS_TABLE_COLUMNS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + '/list-positions/table-columns')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITIONS_TABLE_FILTERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + '/list-positions/table-filters')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITIONS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + '/list-positions', params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITION_OFFERS_TABLE_COLUMNS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + '/list-position-offers/table-columns')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITION_OFFERS_TABLE_FILTERS](context, params) {
    let id = params.id;

    params.id = undefined;

    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/list-position-offers/${id}/table-filters`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITION_OFFERS](context, params) {
    let id = params.id;

    params.id = undefined;

    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/list-position-offers/${id}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_LIVE_LIST_POSITION](context, positionId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/show-position/${positionId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_LIVE_LIST_POSITION_LAST_VIEW_DATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/update-position-last-view-date', params.id, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_LIVE_LIST_POSITION_COST_BASE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/update-position-cost-base', params.id, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [REFRESH_LIVE_LIST_POSITION_COST_BASE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/refresh-position-cost-base', params.id, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_LIVE_LIST_POSITION_LEAD_OFFER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/update-position-lead-offer', params.id, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_LIVE_LIST_POSITION_APPROVED](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/update-position-offer-approved', params.id, params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_LIVE_LIST_POSITION_ALL_APPROVED](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.update(PATH + '/update-position-all-offers-approved', params.id, { value: params.value })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
