import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";
const PATH = 'mappings';
export const TABLE_INIT_URL = API_PATH + PATH+"/table/init";

// action types
export const GET_MAPPINGS = "getMappings";
export const GET_DETAILS = "getMappingDetails";
export const UPDATE_DETAIL = "updateMappingDetail";
export const GET_MAPPING = "getMapping";
export const GET_MAPPING_GROUPED = "getMappingGrouped";
export const GET_MAPPING_WITH_GROUP = "getMappingWithGroup";
export const CREATE_MAPPING = "createMapping";
export const UPDATE_MAPPING = "updateMapping";
export const UPLOAD_MAPPING = "uploadMapping";
export const DOWNLOAD_MAPPING_DETAILS = "downloaddMappingDetails";
export const DOWNLOAD_MAPPING = "downloaddMapping";
export const DELETE_MAPPING_DETAIL = "deleteMappingDetail";
export const GET_MAPPINGS_INIT = "getMappingsTableInit";
export const SET_ERROR = "setError";

// mutation types

const state = {
    errors: {}
};

const getters = {

};

const actions = {

    [GET_MAPPINGS](context,typeId) {
        return new Promise((resolve, reject) => {
            ApiService.search(PATH,typeId)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_DETAILS](context, params) {
      return new Promise((resolve, reject) => {
          ApiService.query(PATH + '/' + params.mappingId + '/show-details', params)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },
    [UPDATE_DETAIL](context, params) {
      return new Promise((resolve, reject) => {
        ApiService.update(PATH + '/details', params.id, params.params)
          .then(({ data }) => {
              resolve(data);
          })
          .catch(({ response }) => {
              reject(response.data);
          });
      });
    },
    [GET_MAPPING_WITH_GROUP](context,params) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH,params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_MAPPING](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_MAPPING_GROUPED](context,params) {
        return new Promise((resolve, reject) => {
            ApiService.getType(PATH+'/grouped',params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [CREATE_MAPPING](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH,params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_MAPPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH, payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPLOAD_MAPPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH+'/upload',payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DOWNLOAD_MAPPING_DETAILS](context, params) {
      return new Promise((resolve, reject) => {
          ApiService.query(PATH + '/download-details', params)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },
    [DOWNLOAD_MAPPING](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,'download')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_MAPPING_DETAIL](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(PATH + '/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [GET_MAPPINGS_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH+"/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
