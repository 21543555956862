import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";

// action types
export const GET_USER_FILTERS = "getUserFilters";
export const GET_USER_FILTER = "getUserFilter";
export const STORE_USER_FILTER = "storeUserFilter";
export const UPDATE_USER_FILTER = "updateUserFilter";
export const DELETE_USER_FILTERS = "deleteUserFilter";

// mutation types

const state = {};

const getters = {};

const actions = {
    [GET_USER_FILTERS](context, type) {
        return new Promise((resolve, reject) => {
            ApiService.query("userFilters", {type:type})
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [GET_USER_FILTER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("userFilters", id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_USER_FILTERS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.deleteSpecific("userFilters", id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [STORE_USER_FILTER](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("userFilters", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_USER_FILTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("userFilters", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
