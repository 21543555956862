import ApiService from "@/common/api.service";

// action types
export const GET_TAG_CATEGORY_TYPES = "gettagCategoryTypes";

// mutation types
export const SET_TAG_CATEGORY_TYPES = "settagCategoryTypes";
export const SET_ERROR = "setError";

const state = {
    errors: {},
    tagCategoryTypes: []
};

const getters = {
    tagCategoryTypes(state) {
        return state.tagCategoryTypes;
    }
};

const actions = {
    [GET_TAG_CATEGORY_TYPES](context) {
        return new Promise(resolve => {
            ApiService.get("tagCategoryTypes")
                .then(({ data }) => {
                    context.commit(SET_TAG_CATEGORY_TYPES, data);
                    resolve(data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TAG_CATEGORY_TYPES](state, tagCategoryTypes) {
        state.tagCategoryTypes = tagCategoryTypes.data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
