import ApiService from "@/common/api.service";
import RequestService from "@/common/offer.service";
import { API_PATH } from "@/common/config";

export const TABLE_INIT_URL = API_PATH + "clientRequests/table/init";

// action types
export const SEARCH_CLIENT_REQUESTS = "searchClientRequest";
export const STORE_CLIENT_REQUEST = "storeClientRequest";
export const UPDATE_CLIENT_REQUEST = "updateClientRequest";
export const SUGGEST_TAGS_R = "suggestTagsR";
export const SEARCH_TAGS_BY_CATEGORY_R = "searchTagsByCategoryR";
export const SET_SELECT_CATEGORY_OPTIONS_R = "setSelectCategoryOptionsR";
export const GET_CLIENT_REQUEST_COUNT = "getClientRequestCount";
export const GET_CLIENT_REQUEST_TABLE_INIT = "getClientRequestTableInit";
export const RESET_REQUEST_DATA = "resetRequestData";
export const SET_FIELD_CHANGE_R = "fieldChangeR";

// mutation types
export const SET_CLIENT_REQUEST = "setClientRequest";
export const SET_SELECT_CATEGORY_OPTION_R = "setSelectCategoryOptionR";
export const SET_ERROR_R = "setErrorR";

const state = {
    errors: {},
    clientRequests: [],
    selectCategoryOptionsR: {},
    fieldChangeR: false,
};

const getters = {
    clientRequests(state) {
        return state.clientRequests;
    },
    selectCategoryOptionsR(state) {
        return state.selectCategoryOptionsR;
    },
    fieldChangeR(state) {
        return state.fieldChangeR;
    },
    selectOptionsByCategoryR: state => name => {
        return typeof state.selectCategoryOptionsR[name] !== "undefined"
            ? state.selectCategoryOptionsR[name]
            : null;
    }
};

const actions = {
    [SEARCH_CLIENT_REQUESTS](context, search) {
        return new Promise((resolve, reject) => {
            ApiService.search("clientRequests", search)
                .then(({ data }) => {
                    context.commit(SET_CLIENT_REQUEST, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [STORE_CLIENT_REQUEST](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("clientRequests", params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [GET_CLIENT_REQUEST_COUNT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query("clientRequests/table/data", params)
                .then(({ data }) => {
                    resolve(data.filtered);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [GET_CLIENT_REQUEST_TABLE_INIT](context) {
        return new Promise((resolve, reject) => {
            ApiService.query("clientRequests/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [SUGGEST_TAGS_R](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("clientRequests/suggest", params)
                .then(({ data }) => {
                    context.dispatch(SET_SELECT_CATEGORY_OPTIONS_R, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [SEARCH_TAGS_BY_CATEGORY_R](context, category) {
        return new Promise((resolve, reject) => {
            ApiService.post("clientRequests/suggest", {
                tags: [
                    {
                        category: category,
                        value: "-"
                    }
                ]
            })
                .then(({ data }) => {
                    let options = data.data[0].options || [];
                    context.commit(SET_SELECT_CATEGORY_OPTION_R, {
                        category: category,
                        options: options
                    });
                    resolve(options);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR_R, response.data?response.data.error_description:'');
                    reject(response.data);
                });
        });
    },
    [SET_SELECT_CATEGORY_OPTIONS_R](context, tags) {
        tags.forEach(tag => {
            if (typeof tag.options !== "undefined") {
                context.commit(SET_SELECT_CATEGORY_OPTION_R, tag);
            }
        });
    },
    [UPDATE_CLIENT_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("clientRequests", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [RESET_REQUEST_DATA](context, offer) {
        let tagCategories = context.rootState.tagCategory.tagCategories;
        RequestService.resetRequestData(offer, tagCategories);
    }
};

const mutations = {
    [SET_ERROR_R](state, error) {
        state.errors = error;
    },
    [SET_FIELD_CHANGE_R](state, set) {
        state.fieldChangeR = set;
    },
    [SET_CLIENT_REQUEST](state, clientRequests) {
        state.clientRequests = clientRequests.data;
        state.errors = {};
    },
    [SET_SELECT_CATEGORY_OPTION_R](state, optionData) {
        state.selectCategoryOptionsR[optionData.category] = optionData.options;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
