import Vue from "vue";
import Router from "vue-router";
import AuthGuard from "@/guards/auth";
import CopperAuthGuard from "@/guards/copper-auth";
import CopperContextGuard from "@/guards/copper-context";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/views/theme/Base"),
            children: [
                {
                  path: "/profile",
                  name: "profile",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/profile/Profile.vue"),
                  meta: {
                      permissions: []
                  }
                },
                {
                    path: "/dashboard",
                    name: "dashboard",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/dashboard/Dashboard.vue"),
                    meta: {
                        permissions: []
                    }
                },
                {
                    path: "/offer",
                    name: "offer",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/offer/Offer.vue"),
                    meta: {
                        permissions: ['offers-create']
                    }
                },
                {
                    path: "/offer/:id",
                    name: "edit-offer",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/offer/EditOffer.vue"),
                    meta: {
                        permissions: ['offers-edit']
                    }
                },
                {
                    path: "/quick-offer",
                    name: "new-quick-offer",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/quickOffer/NewQuickOffer.vue"),
                    meta: {
                        permissions: ['offers-create']
                    }
                },
                {
                    path: "/quick-offer/:id",
                    name: "update-quick-offer",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/quickOffer/UpdateQuickOffer.vue"),
                    meta: {
                        permissions: ['offers-edit']
                    }
                },
                // {
                //     path: "/request",
                //     name: "request",
                //     beforeEnter: AuthGuard,
                //     component: () => import("@/views/pages/request/Request.vue"),
                //     meta: {
                //         permissions: ['requests-create']
                //     }
                // },
                // {
                //     path: "/request/:id",
                //     name: "edit-request",
                //     beforeEnter: AuthGuard,
                //     component: () => import("@/views/pages/request/EditRequest.vue"),
                //     meta: {
                //         permissions: ['requests-edit']
                //     }
                // },
                // {
                //     path: "/quick-request",
                //     name: "new-quick-request",
                //     beforeEnter: AuthGuard,
                //     component: () => import("@/views/pages/quickRequest/NewQuickRequest.vue"),
                //     meta: {
                //         permissions: ['requests-create']
                //     }
                // },
                // {
                //     path: "/quick-request/:id",
                //     name: "update-quick-request",
                //     beforeEnter: AuthGuard,
                //     component: () => import("@/views/pages/quickRequest/UpdateQuickRequest.vue"),
                //     meta: {
                //         permissions: ['requests-edit']
                //     }
                // },
                {
                  path: "/live-list",
                  name: "live-list",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/liveList/LiveList.vue"),
                  meta: {
                      permissions: ['live-list-view-all']
                  }
                },
                {
                  path: "/price-lists",
                  name: "price-lists",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/priceLists/ListPriceLists.vue"),
                  meta: {
                      permissions: ['price-lists-view-self', 'price-lists-view-team', 'price-lists-view-all']
                  }
                },
                {
                  path: "/price-lists/create",
                  name: "price-lists-create",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/priceLists/EditPriceList.vue"),
                  meta: {
                      permissions: ['price-lists-create']
                  }
                },
                {
                  path: "/price-lists/:id",
                  name: "price-lists-edit",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/priceLists/EditPriceList.vue"),
                  meta: {
                      permissions: ['price-lists-view-self', 'price-lists-view-team', 'price-lists-view-all']
                  }
                },
                {
                    path: "/users",
                    name: "users",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/users/Users.vue"),
                    meta: {
                        permissions: ['users-view-self', 'users-view-team', 'users-view-all']
                    }
                },
                {
                    path: "users/new-user",
                    name: "new-user",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/users/StoreUser.vue"),
                    meta: {
                        permissions: ['users-create']
                    }
                },
                {
                    path: "users/:id",
                    name: "edit-user",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/users/StoreUser.vue"),
                    meta: {
                        permissions: ['users-edit']
                    }
                },
                {
                    path: "/teams",
                    name: "teams",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/teams/Teams.vue"),
                    meta: {
                        permissions: ['teams-view-self', 'teams-view-team', 'teams-view-all']
                    }
                },
                {
                    path: "teams/new-team",
                    name: "new-team",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/teams/StoreTeam.vue"),
                    meta: {
                        permissions: ['teams-create']
                    }
                },
                {
                    path: "teams/:id",
                    name: "edit-team",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/teams/StoreTeam.vue"),
                    meta: {
                        permissions: ['teams-edit']
                    }
                },
                {
                    path: "company-contacts",
                    name: "company-contacts",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/companyContacts/CompanyContacts.vue"),
                    meta: {
                        permissions: []
                    }
                },
                {
                    path: "company-contacts/:id",
                    name: "view-contact",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/companyContacts/ViewContact.vue"),
                    meta: {
                        permissions: []
                    }
                },
                {
                    path: "/list-offers",
                    name: "list-offers",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/listOffers/ListOffers.vue"),
                    meta: {
                        permissions: ['offers-view-self', 'offers-view-team', 'offers-view-all']
                    }
                },
                // {
                //     path: "/list-requests",
                //     name: "list-requests",
                //     beforeEnter: AuthGuard,
                //     component: () => import("@/views/pages/listRequests/ListRequests.vue"),
                //     meta: {
                //         permissions: ['offers-view-self', 'offers-view-team', 'offers-view-all']
                //     }
                // },
                {
                    path: "aliases",
                    name: "aliases",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/aliases/ShowAliases.vue"),
                    meta: {
                        permissions: []
                    }
                },
                {
                    path: "aliases/:id",
                    name: "edit-alias",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/aliases/EditAlias.vue"),
                    meta: {
                        permissions: ['aliases-edit']
                    }
                },
                {
                    path: "mappings",
                    name: "mappings",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/mappings/ShowMappings.vue"),
                    meta: {
                        permissions: ['mappings-view-self', 'mappings-view-team', 'mappings-view-all']
                    }
                },
                {
                    path: "mapping/:id",
                    name: "edit-mappings",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/mappings/EditMapping.vue"),
                    meta: {
                        permissions: ['mappings-edit']
                    }
                },
                {
                    path: "mapping",
                    name: "create-mappings",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/mappings/EditMapping.vue"),
                    meta: {
                        permissions: ['mappings-create']
                    }
                },
                {
                  path: "mapping-values/:mappingId",
                  name: "edit-mapping-values",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/mappings/EditMappingValues.vue"),
                  meta: {
                      permissions: ['mapping-values-view-self', 'mapping-values-view-team', 'mapping-values-view-all']
                  }
              },
                {
                    path: "normalize-datas",
                    name: "normalize-data",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/normalizeData/ShowNormalizeData.vue"),
                    meta: {
                        permissions: ['normalize-data-view-self', 'normalize-data-view-team', 'normalize-data-view-all']
                    }
                },
                {
                  path: "transport-data",
                  name: "transport-data",
                  beforeEnter: AuthGuard,
                  component: () => import("@/views/pages/transportData/ShowTransportData.vue"),
                  meta: {
                      permissions: ['transport-data-view-self', 'transport-data-view-team', 'transport-data-view-all']
                  }
                },
                {
                    path: "price-config",
                    name: "price-config",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/config/Config.vue"),
                    meta: {
                        permissions: ['price-config-self', 'price-config-team', 'price-config-all']
                    }
                },
                {
                    path: "normalize-data/:id",
                    name: "edit-normalize-data",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/normalizeData/EditNormalizeData.vue"),
                    meta: {
                        permissions: ['normalize-data-edit']
                    }
                },
                {
                    path: "normalize-data",
                    name: "create-normalize-data",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/normalizeData/EditNormalizeData.vue"),
                    meta: {
                        permissions: ['normalize-data-create']
                    }
                },
                {
                    path: "list-price",
                    name: "pricing",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/pricing/ShowPricing.vue"),
                    meta: {
                        permissions: ['pricing-view-self', 'pricing-view-team', 'pricing-view-all']
                    }
                },
                {
                    path: "pricing/:id",
                    name: "edit-pricing",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/pricing/EditPricing.vue"),
                    meta: {
                        permissions: ['pricing-edit']
                    }
                },
                {
                    path: "pricing",
                    name: "create-pricing",
                    beforeEnter: AuthGuard,
                    component: () => import("@/views/pages/pricing/EditPricing.vue"),
                    meta: {
                        permissions: ['pricing-create']
                    }
                }
            ]
        },
        {
            path: "/copper-embedded-apps",
            name: "copper-embedded-apps",
            beforeEnter: CopperContextGuard,
            component: () => import("@/views/pages/copper/Base"),
            children: [
                {
                    path: "login",
                    name: "copper-embedded-apps-login",
                    component: () => import("@/views/pages/copper/Login")
                },
                {
                    path: "application",
                    name: "copper-embedded-apps-application",
                    beforeEnter: CopperAuthGuard,
                    component: () => import("@/views/pages/copper/Application")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/views/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/views/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/views/pages/auth/Register")
                },
                {
                    name: "forgot",
                    path: "/forgot",
                    component: () => import("@/views/pages/auth/Forgot")
                },
                {
                    name: "reset",
                    path: "/reset/:token/:email",
                    component: () => import("@/views/pages/auth/Reset")
                }
            ]
        },
        {
          path: "/google-redirect",
          name: "google-redirect",
          component: () => import("@/views/pages/auth/GoogleRedirect")
        },
        {
            path: "*",
            redirect: "/404",
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/views/pages/error/Error-1.vue")
        }
    ]
});
