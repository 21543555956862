import ApiService from "@/common/api.service";
const PATH = 'incoterms';

// action types
export const GET_INCOTERMS = "getIncoterms";
export const GET_ENTITY_INCOTERMS = "getEntityIncoterms";
export const UPDATE_ENTITY_INCOTERM = "updateEntityIncoterm";
export const UPDATE_ENTITY_COUNTRY = "updateEntityCountry";

// mutation types
export const SET_INCOTERMS_LOADING = "setIncotermsLoading";

const state = {
  incotermsLoading: false
};

const getters = {
  incotermsLoading(state) {
    return state.incotermsLoading;
  }
};

const actions = {
  [GET_INCOTERMS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `${PATH}/index`
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [GET_ENTITY_INCOTERMS](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(SET_INCOTERMS_LOADING, true);

      ApiService.get(
        `${PATH}/get-entity-incoterms`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      })
      .finally(() => {
        context.commit(SET_INCOTERMS_LOADING, false);
      });
    });
  },

  [UPDATE_ENTITY_INCOTERM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/update-entity-incoterm`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  },

  [UPDATE_ENTITY_COUNTRY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(
        `${PATH}/update-entity-country`,
        `${payload.entityTypeSlug}/${payload.entityExternalId}`,
        payload
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
    });
  }
};

const mutations = {
  [SET_INCOTERMS_LOADING](state, value) {
    state.incotermsLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
