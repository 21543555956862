import ApiService from "@/common/api.service";
const PATH = 'price-config';

// action types
export const GET_PRICE_CONFIG = "getPriceConfig";
export const UPDATE_PRICE_CONFIG = "updatePriceConfig";
export const SET_ERROR = "setError";
const ID = 1;
// mutation types

const state = {
    errors: {}
};

const getters = {

};

const actions = {

    [GET_PRICE_CONFIG](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,ID)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response?response.data:{});
                });
        });
    },

    [UPDATE_PRICE_CONFIG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH, ID, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
