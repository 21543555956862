import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import snack from "./snack.module";

import selectsData from "./selectsData.module";
import displayPreferences from "./displayPreferences.module";
import companyContact from "./companyContact.module";
import tagCategory from "./tagCategory.module";
import vendorOffer from "./vendorOffer.module";
import vendorOfferSet from "./vendorOfferSet.module";
import vendorOfferSetImportType from "./vendorOfferSetImportType.module";
import validationRule from "./validationRule.module";
import tagCategoryType from "./tagCategoryType.module";
import tagCategoryUse from "./tagCategoryUse.module";
import clientRequest from "./clientRequest.module";
import quickRequest from "./quickRequest.module";
import clientRequestSet from "./clientRequestSet.module";
import quickOffer from "./quickOffer.module";
import users from "./users.module";
import teams from "./teams.module";
import userFilter from "./userFilter.module";
import aliases from "./aliases.module";
import mappings from "./mappings.module";
import mappingValues from "./mappingValues.module";
import interests from "./interests.module";
import offerGroups from "./offerGroups.module";
import copperEntities from "./copperEntities.module";
import incoterms from "./incoterms.module";
import interestFields from "./interestFields.module";
import opportunityPriceLists from "./opportunityPriceLists.module";
import countries from "./countries.module";
import transportData from "./transportData.module";
import liveList from "./liveList.module";
import priceLists from "./priceLists.module";
import pricing from "./pricing.module";
import normalize_data from "./normalize_data.module";
import price_config from "./price_config.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        snack,
        selectsData,
        displayPreferences,
        companyContact,
        tagCategory,
        vendorOffer,
        vendorOfferSet,
        vendorOfferSetImportType,
        validationRule,
        tagCategoryType,
        tagCategoryUse,
        clientRequest,
        quickRequest,
        clientRequestSet,
        quickOffer,
        users,
        teams,
        userFilter,
        aliases,
        mappings,
        mappingValues,
        copperEntities,
        interests,
        interestFields,
        opportunityPriceLists,
        offerGroups,
        incoterms,
        countries,
        transportData,
        liveList,
        priceLists,
        pricing,
        normalize_data,
        price_config
    }
});
