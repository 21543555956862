import ApiService from "@/common/api.service";
const PATH = 'opportunities';

// action types
export const INIT_OPPORTUNITY_PRICE_LISTS_SEARCH = "initOpportunityPriceListsSearch";
export const GET_OPPORTUNITY_PRICE_LISTS = "getOpportunityPriceLists";
export const SHOW_OPPORTUNITY_PRICE_LIST = "showOpportunityPriceList";
export const GET_OPPORTUNITY_PRICE_LIST_PREVIEW_CONTENT = "getOpportunityPriceListPreviewContent";

// mutation types
export const SET_OPPORTUNITY_PRICE_LISTS_LOADING = "setOpportunityPriceListsLoading";

const state = {
  opportunityPriceListsLoading: false
};

const getters = {
  opportunityPriceListsLoading(state) {
    return state.opportunityPriceListsLoading;
  }
};

const actions = {
  [INIT_OPPORTUNITY_PRICE_LISTS_SEARCH](context, opportunityId) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${PATH}/init-price-lists-search/${opportunityId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {
          reject();
        });
    });
  },

  [GET_OPPORTUNITY_PRICE_LISTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${PATH}/get-price-lists/${payload.opportunityId}`,
        { 'sync-price-lists': payload.sync }
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(() => {
        reject();
      });
    });
  },

  [SHOW_OPPORTUNITY_PRICE_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(SET_OPPORTUNITY_PRICE_LISTS_LOADING, true);

      ApiService.query(
        `${PATH}/show-price-list/${payload.opportunityId}/${payload.priceListId}`
      ).then(({ data }) => {
        resolve(data.data);
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        context.commit(SET_OPPORTUNITY_PRICE_LISTS_LOADING, false);
      });
    });
  },

  [GET_OPPORTUNITY_PRICE_LIST_PREVIEW_CONTENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${PATH}/show-price-list-preview-content/${payload.opportunityId}/${payload.priceListId}`
      ).then(({ data }) => {
        resolve(data);
      })
      .catch(() => {
        reject();
      });
    });
  }
};

const mutations = {
  [SET_OPPORTUNITY_PRICE_LISTS_LOADING](state, value) {
    state.opportunityPriceListsLoading = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
