import Vue from "vue";
import Toastr from "@enso-ui/toastr/bulma";
import ToastrPlugin from "@enso-ui/toastr";
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import "bulma/css/bulma.min.css";
import "flatpickr/dist/flatpickr.css";

Vue.component("fa", FontAwesomeIcon);
Vue.component("fal", FontAwesomeLayers);
Vue.component("falt", FontAwesomeLayersText);

Vue.use(ToastrPlugin, {
    layout: Toastr,
    options: {
        duration: 3500,
        position: "right"
    }
});

window.axios = axios;

export default ToastrPlugin;
