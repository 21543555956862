import ApiService from "@/common/api.service";
import UuidService from "@/common/uuid.service";
import SelectionService from "@/common/selection.service";
import OfferService from "@/common/offer.service";
import ObjectsService from "@/common/objects.service";

// action types
export const SEARCH_CLIENT_REQUEST_SETS = "searchClientRequestSets";
export const PROCESS_CLIENT_REQUEST_SET = "processClientRequestSets";
export const STORE_CLIENT_REQUEST_SET = "storeClientRequestSet";
export const SET_TAG_TO_ALL_REQUESTS = "setTagToAllRequests";
export const UPDATE_REQUEST_TAG = "updateRequestTag";
export const SET_NEW_TAG_CATEGORY_TO_REQUESTS = "setNewCategoryToRequests";
export const RESET_DATA_R = "resetDataR";
export const EDIT_REQUEST = "editClientRequestSet";
export const UPDATE_CLIENT_REQUEST_SET = "updateClientRequestSet";

// mutation types
export const SET_CLIENT_REQUEST_SETS = "setClientRequestSets";
export const SET_FORMATTED_SET_TEXT_R = "setFormattedSetTextR";
export const SET_PROCESSED_SET_R = "setProcessedSetRR";
export const SET_CLIENT_REQUEST_SET = "setClientRequestSet";
export const ADD_REQUEST_TABLE_ITEM = "addRequestTableItem";
export const REMOVE_REQUEST_TABLE_ITEM = "removeRequestTableItem";
export const ADD_PART_OF_NEXT_SET_R = "addPartOfNextSetR";
export const REMOVE_PART_OF_NEXT_SET_R = "removePartOfNextSetR";
export const ADD_PART_OF_ALL_R = "addPartOfAllR";
export const REMOVE_PART_OF_ALL_R = "removePartOfAllR";
export const REFRESH_REQUESTS_WITH_PARTS = "refreshRequestsWithPartsR";
export const SET_REQUEST_TABLE_ROWS = "setRequestTableRows";
export const SET_PART_OF_NEXT_SET_R = "setPartOfNextSetR";
export const SET_PART_OF_ALL_R = "setPartOfAllR";
export const SET_ERROR = "setError";
export const ADD_OVERWRITE_R = "addOverwritePartR";
export const OVERWRITE_TAG_TO_ALL_REQUESTS = "overwriteTagToAllRequests";
export const OVERWRITE_REQUEST_TAG = "overwriteRequestTag";



export const SEGMENT_OPTIONS_R = [
    {
        name: "Part of Next Set",
        color: "teal",
        menuComponent: null,
        addAction: ADD_PART_OF_NEXT_SET_R,
        removeAction: REMOVE_PART_OF_NEXT_SET_R
    },
    {
        name: "Part of All",
        color: "light-blue",
        menuComponent: null,
        addAction: ADD_PART_OF_ALL_R,
        removeAction: REMOVE_PART_OF_ALL_R
    },
    {
        name: "Request",
        color: "cyan",
        menuComponent: "RequestMenu",
        addAction: ADD_REQUEST_TABLE_ITEM,
        removeAction: REMOVE_REQUEST_TABLE_ITEM
    }
];

const state = {
    errors: {},
    clientRequestSets: [],
    processedSetR: {},
    requestTableRows: [],
    clientRequestSet: {},
    formattedSetTextR: "",
    partOfNextSetR: [],
    partOfAllR: []
};

const getters = {
    clientRequestSets(state) {
        return state.clientRequestSets;
    },
    processedSetR(state) {
        return state.processedSetR;
    },
    clientRequestSet(state) {
        return state.clientRequestSet;
    },
    formattedSetTextR(state) {
        return state.formattedSetTextR;
    },
    requestTableRows(state) {
        return state.requestTableRows;
    },
    partOfNextSetR(state) {
        return state.partOfNextSetR;
    },
    overwritePartR(state) {
        return state.overwritePartR;
    },
    partOfAllR(state) {
        return state.partOfAllR;
    },
    requestByUuid: state => uuid => {
        return state.requestTableRows.find(e => e.uuid === uuid);
    },
    partByUuidR: state => uuid => {
        return (
            state.partOfNextSetR.find(e => e.uuid === uuid) ||
            state.partOfAllR.find(e => e.uuid === uuid)
        );
    }
};

const actions = {
    [SEARCH_CLIENT_REQUEST_SETS](context, search) {
        return new Promise((resolve, reject) => {
            ApiService.search("clientRequestSets", search)
                .then(({ data }) => {
                    context.commit(SET_CLIENT_REQUEST_SETS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [PROCESS_CLIENT_REQUEST_SET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("clientRequestSets/process", params)
                .then(({ data }) => {
                    try {

                        context.commit(SET_CLIENT_REQUEST_SET, params);
                        context.commit(SET_PROCESSED_SET_R, data.data);

                        let segmentedText = data.data.set.text;

                        let orderedEntities = data.data.set.entities.sort(  (a, b) => b.end - a.end  );
                        let segmentOptions = ObjectsService.replaceObjectKeys(SEGMENT_OPTIONS_R,'name');
                        let tagCategories = context.rootState.tagCategory.tagCategories;
                        let cats = ObjectsService.replaceObjectKeys(tagCategories,'name');

                        orderedEntities.forEach(segment => {
                            let type = segmentOptions[segment.category] || {};
                            let defaults = {};
                            if (type.name !== "Request") {
                                let partMatch = data.data.parts.find( p => p.text === segment.value );

                                if (partMatch) {
                                    defaults.category = partMatch.entities[0] ? partMatch.entities[0].category : null;
                                    defaults.formatted_value = partMatch.entities[0] ? partMatch.entities[0].formatted_value : null;
                                    defaults.type = segment.category ||'';
                                    defaults.order = segment.start-10000;
                                }

                            } else {

                                let offerMatch = data.data.offers.find( p => p.text === segment.value);
                                defaults = Object.assign( defaults, OfferService.setOfferTags( offerMatch, tagCategories ) );

                            }

                            let uuid = UuidService.generateUuid();

                            let component = SelectionService.getComponentInnerHtml(  { type: type,  secondary: defaults.category ? cats[defaults.category] : null },  segment.value, uuid );

                            segmentedText = SelectionService.replaceRange( segmentedText,  segment.start, segment.end,  component  );

                            defaults = Object.assign(defaults, {   uuid: uuid,  raw: segment.value,  segmented: component,  start: segment.start,  end: segment.end  });

                            if (type.name === "Request") {
                                defaults = OfferService.createOffer( defaults,  tagCategories );
                            }

                            context.commit(type.addAction, defaults);
                        });
                        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
                        context.getters.requestTableRows.forEach(
                            offerTableRow => {
                                OfferService.setOfferTags(
                                    offerTableRow,
                                    tagCategories
                                );
                            }
                        );
                        context.commit(SET_FORMATTED_SET_TEXT_R, segmentedText);
                    } catch (error) {
                        console.error(error);
                    }

                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [STORE_CLIENT_REQUEST_SET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("clientRequestSets", params)
                .then(({ data }) => {
                    context.commit(SET_CLIENT_REQUEST_SET, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [ADD_REQUEST_TABLE_ITEM](context, payload) {
        let uuid = UuidService.generateUuid();
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );

        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetTextR,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT_R, newText);
        let offer = OfferService.createOffer(
            {
                raw: payload.selection.text,
                expanded: payload.selection.text,
                segmented: component,
                start: payload.selection.realStart,
                end: payload.selection.realEnd,
                uuid: uuid
            },
            context.rootState.tagCategory.tagCategories
        );

        context.commit(ADD_REQUEST_TABLE_ITEM, offer);
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
    },
    [REMOVE_REQUEST_TABLE_ITEM](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetTextR,
            uuid
        );
        context.commit(SET_FORMATTED_SET_TEXT_R, newText);
        context.commit(REMOVE_REQUEST_TABLE_ITEM, uuid);
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
    },
    [ADD_PART_OF_NEXT_SET_R](context, payload) {
        let uuid = UuidService.generateUuid();
        var formattedValue = payload.data.suggestedTag || payload.selection.text;
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );
        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetTextR,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT_R, newText);

        context.commit(ADD_PART_OF_NEXT_SET_R, {
            raw: payload.selection.text,
            segmented: component,
            start: payload.selection.realStart,
            end: payload.selection.realEnd,
            category: payload.data.secondary.name,
            formatted_value: formattedValue,
            order:payload.selection.realStart,
            type:'Part of Next Set',
            uuid: uuid
        });
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
        context.dispatch(SET_TAG_TO_ALL_REQUESTS, {
            value: payload.selection.text,
            formatted_value: formattedValue,
            category: payload.data.secondary.name,
            order:payload.selection.realStart,
            offer:{uuid:''}
        });
    },
    [REMOVE_PART_OF_NEXT_SET_R](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetTextR,
            uuid
        );

        context.commit(SET_FORMATTED_SET_TEXT_R, newText);
        context.commit(REMOVE_PART_OF_NEXT_SET_R, uuid);
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
    },
    [ADD_PART_OF_ALL_R](context, payload) {
        let uuid = UuidService.generateUuid();
        var formattedValue = payload.data.suggestedTag || payload.selection.text;
        let component = SelectionService.getComponentInnerHtml(
            payload.data,
            payload.selection.text,
            uuid
        );
        let newText = SelectionService.replace(
            payload.selection,
            context.getters.formattedSetTextR,
            component
        );
        context.commit(SET_FORMATTED_SET_TEXT_R, newText);
        context.commit(ADD_PART_OF_ALL_R, {
            raw: payload.selection.text,
            segmented: component,
            start: payload.selection.realStart,
            end: payload.selection.realEnd,
            category: payload.data.secondary.name,
            formatted_value: formattedValue,
            order:payload.selection.realStart,
            type:'Part of All',
            uuid: uuid
        });
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
        context.dispatch(SET_TAG_TO_ALL_REQUESTS, {
            value: payload.selection.text,
            formatted_value: formattedValue,
            category: payload.data.secondary.name,
            order:payload.selection.realStart,
            offer:{uuid:''}
        });
    },
    [REMOVE_PART_OF_ALL_R](context, uuid) {
        let newText = SelectionService.clearById(
            context.getters.formattedSetTextR,
            uuid
        );
        context.commit(SET_FORMATTED_SET_TEXT_R, newText);
        context.commit(REMOVE_PART_OF_ALL_R, uuid);
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
    },
    [REFRESH_REQUESTS_WITH_PARTS](context) {
        OfferService.refreshOfferParts(
            context.getters.requestTableRows,
            context.getters.partOfNextSetR,
            context.getters.partOfAllR,
            context.rootState.tagCategory.tagCategories
        );
    },
    [UPDATE_REQUEST_TAG](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;
        if(categories.find(x => x.name === payload.category).tag_category_type === 'text'){
            let q = payload.offer[payload.category]['tags'].map(function(o) { if(o.type){ return -1; }else{ return o.order?o.order:0 } });
            payload.order = Math.max.apply(Math, (q.length>0?q:[0]))+1;
            payload.offer[payload.category]['tags'].push(OfferService.setTag(payload));
        }else{
            payload.offer[payload.category]['tags'][0] = OfferService.setTag(payload);
        }

        payload.offer[payload.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(payload.offer[payload.category]['tags']);
        OfferService.setOfferTags(
            payload.offer,
            context.rootState.tagCategory.tagCategories
        );
    },
    [SET_TAG_TO_ALL_REQUESTS](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;

        context.getters.requestTableRows.forEach(offer => {
            let selectionIndex = SelectionService.getClosestIndex(
                offer.raw,
                payload.value,
                payload.originalStart || 0
            );

            if(payload.saveType === 'overwrite' && selectionIndex > -1 && payload.offer.uuid !== offer.uuid){
              offer = OfferService.removeRequestTag(offer,categories,selectionIndex);
            }
            if (
                payload.offer.uuid !== offer.uuid &&
                selectionIndex > -1 &&
                OfferService.isIndexFree(offer, categories, selectionIndex)
            ) {

                payload.start = selectionIndex;
                payload.end = selectionIndex + payload.value.length;
                if(categories.find(x => x.name === payload.category).tag_category_type === 'text'){
                    offer[payload.category]['tags'].push(OfferService.setTag(payload));
                }else{
                    offer[payload.category]['tags'][0] = OfferService.setTag(payload);
                }
                offer[payload.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(offer[payload.category]['tags']);

                OfferService.setOfferTags(offer, categories);
            }
        });
    },
    [SET_NEW_TAG_CATEGORY_TO_REQUESTS](context, category) {
        context.getters.requestTableRows.forEach(offer => {
            offer[category] = {'formatted_value':'','tags':[{
                uuid: null,
                start: null,
                end: null,
                value: "",
                formatted_value:""
            }]};
        });
    },
    [RESET_DATA_R](context) {
        context.commit(SET_REQUEST_TABLE_ROWS, []);
        context.commit(SET_PART_OF_NEXT_SET_R, []);
        context.commit(SET_PART_OF_ALL_R, []);
        let setText = context.getters.processedSetR.set
            ? context.getters.processedSetR.set.text
            : null;
        context.commit(SET_FORMATTED_SET_TEXT_R, setText);
    },
    [EDIT_REQUEST](context, id) {
        if(!id){
            return;
        }
        context.dispatch(RESET_DATA_R);
        return new Promise((resolve, reject) => {
            ApiService.getRB("clientRequestSets/"+id)
                .then(({ data }) => {
                    try {
                        context.commit(SET_CLIENT_REQUEST_SET, data.details);
                        context.commit(SET_PROCESSED_SET_R, data);

                        let segmentedText = data.set.text;

                        let orderedEntities = data.set.entities.sort(  (a, b) => b.end - a.end  );
                        let segmentOptions = ObjectsService.replaceObjectKeys(SEGMENT_OPTIONS_R,'name');
                        let tagCategories = context.rootState.tagCategory.tagCategories;
                        let cats = ObjectsService.replaceObjectKeys(tagCategories,'name');

                        orderedEntities.forEach(segment => {
                            let type = segmentOptions[segment.category] || {};
                            let defaults = {};
                            if (type.name !== "Request") {
                                let partMatch = data.parts.find( p => p.text === segment.value );

                                if (partMatch) {
                                    defaults.category = partMatch.entities[0] ? partMatch.entities[0].category : null;
                                    defaults.formatted_value = partMatch.entities[0] ? partMatch.entities[0].formatted_value : null;
                                    defaults.type = segment.category ||'';
                                    defaults.order = segment.order ||segment.start;
                                }

                            } else {

                                let offerMatch = data.offers.find( p => p.text === segment.value);
                                defaults = Object.assign( defaults, OfferService.setOfferTags( offerMatch, tagCategories ) );

                            }

                            let uuid = UuidService.generateUuid();

                            let component = SelectionService.getComponentInnerHtml(  { type: type,  secondary: defaults.category ? cats[defaults.category] : null },  segment.value, uuid );

                            segmentedText = SelectionService.replaceRange( segmentedText,  segment.start, segment.end,  component  );

                            defaults = Object.assign(defaults, {   uuid: uuid,  raw: segment.value,  segmented: component,  start: segment.start,  end: segment.end  });

                            if (type.name === "Request") {
                                defaults = OfferService.createOffer( defaults,  tagCategories );
                            }

                            context.commit(type.addAction, defaults);
                        });
                        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
                        context.getters.requestTableRows.forEach(
                            offerTableRow => {
                                OfferService.setOfferTags(
                                    offerTableRow,
                                    tagCategories
                                );
                            }
                        );
                        context.commit(SET_FORMATTED_SET_TEXT_R, segmentedText);
                    } catch (error) {
                        console.error(error);
                    }

                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [UPDATE_CLIENT_REQUEST_SET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put("clientRequestSets/"+params.id, params.params)
                .then(({ data }) => {
                    context.commit(SET_CLIENT_REQUEST_SET, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [ADD_OVERWRITE_R](context, payload) {
        var formattedValue = payload.formatted_value;
        context.dispatch(REFRESH_REQUESTS_WITH_PARTS);
        context.dispatch(OVERWRITE_TAG_TO_ALL_REQUESTS, {
            value: payload.value,
            formatted_value: formattedValue,
            category: payload.category,
            order:0,
            type:payload.type,
            offer:payload.offer,
            onlyEmpty:payload.onlyEmpty
        });
    },

    [OVERWRITE_TAG_TO_ALL_REQUESTS](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;

        let tag = payload;
        const type = payload.type;
        context.getters.requestTableRows.some(offer => {

            if(tag.onlyEmpty && offer[tag.category]['formatted_value'] && offer.uuid !== tag.offer.uuid){
                return false;
            }
            let selectionIndex = SelectionService.getClosestIndex(
                offer.raw,
                tag.value,
                tag.originalStart || 0
            );
            let isFree = selectionIndex > -1 ? OfferService.isIndexFree(offer, categories.filter(x => x.name !== tag.category), selectionIndex):true;
            tag.uuid = UuidService.generateUuid();
            tag.start = selectionIndex > -1 && isFree?selectionIndex:null;
            tag.end = selectionIndex > -1 && isFree?selectionIndex + tag.value.length:null;
            tag.order = selectionIndex > -1?null:(selectionIndex - 10000);
            tag.type = selectionIndex > -1?'':type;
            offer[tag.category]['tags'] = [];
            offer[tag.category]['tags'][0] = OfferService.setTag(tag);
            offer[tag.category]['formatted_value'] = OfferService.orderCategoryFormattedValue(offer[tag.category]['tags']);

            OfferService.setOfferTags(offer,categories);
        });
    },
    [OVERWRITE_REQUEST_TAG](context, payload) {
        const categories = context.rootState.tagCategory.tagCategories;
        let offer = payload.offer;

        offer[payload.category]['tags'].map(tag => {
            if(!tag.start){
                let selectionIndex = SelectionService.getClosestIndex(
                    offer.raw,
                    tag.value,
                    0
                );
                let isFree = selectionIndex > -1 ? OfferService.isIndexFree(offer, categories.filter(x => x.name !== tag.category), selectionIndex):true;
                tag.uuid = UuidService.generateUuid();
                tag.start = selectionIndex > -1 && isFree?selectionIndex:null;
                tag.end = selectionIndex > -1 && isFree?selectionIndex + tag.value.length:null;
            }
        })

        OfferService.setOfferTags(offer,categories);
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CLIENT_REQUEST_SETS](state, clientRequestSets) {
        state.clientRequestSets = clientRequestSets.data;
    },
    [SET_PROCESSED_SET_R](state, processedSetR) {
        state.processedSetR = processedSetR;
    },
    [SET_CLIENT_REQUEST_SET](state, clientRequestSet) {
        state.clientRequestSet = clientRequestSet;
    },
    [SET_REQUEST_TABLE_ROWS](state, requestTableRows) {
        state.requestTableRows = requestTableRows;
    },
    [SET_PART_OF_NEXT_SET_R](state, partOfNextSetR) {
        state.partOfNextSetR = partOfNextSetR;
    },
    [SET_PART_OF_ALL_R](state, partOfAllR) {
        state.partOfAllR = partOfAllR;
    },
    [SET_FORMATTED_SET_TEXT_R](state, formattedSetTextR) {
        state.formattedSetTextR = formattedSetTextR;
    },
    [ADD_REQUEST_TABLE_ITEM](state, offer) {
        state.requestTableRows.push(offer);
    },
    [REMOVE_REQUEST_TABLE_ITEM](state, uuid) {
        let index = state.requestTableRows.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.requestTableRows.splice(index, 1);
        }
    },
    [ADD_PART_OF_NEXT_SET_R](state, item) {
        state.partOfNextSetR.push(item);
    },
    [REMOVE_PART_OF_NEXT_SET_R](state, uuid) {
        let index = state.partOfNextSetR.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.partOfNextSetR.splice(index, 1);
        }
    },
    [ADD_PART_OF_ALL_R](state, item) {
        state.partOfAllR.push(item);
    },
    [REMOVE_PART_OF_ALL_R](state, uuid) {
        let index = state.partOfAllR.findIndex(e => e.uuid === uuid);
        if (index > -1) {
            state.partOfAllR.splice(index, 1);
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
