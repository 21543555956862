import ApiService from "@/common/api.service";
import { API_PATH } from "@/common/config";
const PATH = 'normalize-data';
export const TABLE_INIT_URL = API_PATH + PATH+"/table/init";

// action types
export const GET_NORMALIZE_DATAS = "getNormalizeDatas";
export const GET_NORMALIZE_DATA = "getNormalizeData";
export const GET_NORMALIZE_DATA_TYPES = "getNormalizeDataTypes";
export const CREATE_NORMALIZE_DATA = "createNormalizeData";
export const UPDATE_NORMALIZE_DATA = "updateNormalizeData";
export const DELETE_NORMALIZE_DATA = "deleteNormalizeData";
export const GET_NORMALIZE_DATA_INIT = "getNormalizeDataTableInit";
export const SET_ERROR = "setError";

// mutation types

const state = {
    errors: {}
};

const getters = {

};

const actions = {

    [GET_NORMALIZE_DATAS](context) {
        return new Promise((resolve, reject) => {
            ApiService.search(PATH)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_NORMALIZE_DATA_TYPES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH+'/type')
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_NORMALIZE_DATA](context,id) {
        return new Promise((resolve, reject) => {
            ApiService.get(PATH,id)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [CREATE_NORMALIZE_DATA](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH,params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [UPDATE_NORMALIZE_DATA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH, payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },
    [DELETE_NORMALIZE_DATA](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(PATH,id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_NORMALIZE_DATA_INIT]() {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH+"/table/init")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
