// action types
export const SHOW_SNACK = "showSnack";

// mutation types
export const SET_SNACK = "setSnack";

const DEFAULT_SNACK = {
    type: "success",
    message: "Success",
    timeout: 6000
};

export default {
    state: {
        globalSnack: {
            show: false
        }
    },
    getters: {
        globalSnack: state => {
            return state.globalSnack;
        }
    },
    actions: {
        [SHOW_SNACK](context, snack = {}) {
            context.commit(SET_SNACK, snack);
        }
    },
    mutations: {
        [SET_SNACK](state, payload) {
            state.globalSnack.show = false;
            state.globalSnack.show = true;
            state.globalSnack.type = payload.type || DEFAULT_SNACK.type;
            state.globalSnack.message =
                payload.message || DEFAULT_SNACK.message;
            state.globalSnack.timeout =
                payload.timeout || DEFAULT_SNACK.timeout;
        }
    }
};
