import ApiService from "@/common/api.service";

// action types
export const SEARCH_TAG_CATEGORIES = "getTagCategories";
export const STORE_TAG_CATEGORY = "storeTagCategory";
export const REFRESH_TAG_CATEGORY_SORT = "refreshTagCategorySort";
export const GET_ONLY_SELECT_CATEGORIES = "getOnlySelectCategories";

// mutation types
export const SET_TAG_CATEGORIES = "setTagCategories";
export const ADD_TAG_CATEGORY = "addTagCategory";
export const SET_ERROR = "setError";
export const ADD_NEW_FIELD_NAME = "Add new...";

const state = {
    errors: {},
    tagCategories: []
};

const getters = {
    tagCategories(state) {
        return state.tagCategories;
    }
};

const actions = {
    [SEARCH_TAG_CATEGORIES](context, data) {
        return new Promise(resolve => {
            ApiService.search("tagCategories/use/"+data.type, data.search)
                .then(({ data }) => {
                    context.commit(SET_TAG_CATEGORIES, data.data);
                    resolve(data.data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [GET_ONLY_SELECT_CATEGORIES](context, data) {
        return new Promise(resolve => {
            ApiService.get("tagCategories/select/"+data.type)
                .then(({ data }) => {
                    context.commit(SET_TAG_CATEGORIES, data.data);
                    resolve(data.data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [STORE_TAG_CATEGORY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("tagCategories", params)
                .then(({ data }) => {
                    try {
                        context.commit(ADD_TAG_CATEGORY, data.data);
                        context.dispatch(REFRESH_TAG_CATEGORY_SORT);
                        resolve(data.data);
                    } catch (error) {
                        console.error(error);
                    }
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    },
    [REFRESH_TAG_CATEGORY_SORT](context) {
        return new Promise((resolve, reject) => {
            ApiService.resort("tagCategories")
                .then(({ data }) => {
                    context.commit(SET_TAG_CATEGORIES, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.error_description);
                    reject(response);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TAG_CATEGORIES](state, tagCategories) {
        state.tagCategories = tagCategories;
        state.errors = {};
    },
    [ADD_TAG_CATEGORY](state, tagCategory) {
        state.tagCategories.push(tagCategory);
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
