const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const EXPIRES_AT_KEY = "expires_at";

export const getToken = () => {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getExpiresAt = () => {
    return parseInt(window.localStorage.getItem(EXPIRES_AT_KEY));
};

export const saveTokens = tokens => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, tokens.access_token);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token);

    const expiresAt = Date.now() + tokens.expires_in;
    window.localStorage.setItem(EXPIRES_AT_KEY, expiresAt);
};

export const destroyTokens = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(EXPIRES_AT_KEY);
};

export default {
    getToken,
    getRefreshToken,
    getExpiresAt,
    saveTokens,
    destroyTokens
};
