import ApiService from "@/common/api.service";
const PATH = 'price-lists';

// action types
export const GET_PRICE_LISTS = "getPriceLists";
export const GET_PRICE_LIST = "getPriceList";
export const GET_PRICE_LIST_INTEREST_FIELDS = "getPriceListInterestFields";
export const GET_PRICE_LIST_CONTACTS = "getPriceListContacts";
export const GET_PRICE_LIST_ALL_CONTACTS = "getPriceListAllContacts";
export const GET_PRICE_LIST_CONTACTS_BY_POSITION = "getPriceListContactsByPosition";
export const GET_PRICE_LIST_POSITIONS_TABLE_COLUMNS = "getPriceListPositionsTableColumns";
export const GET_PRICE_LIST_POSITIONS_TABLE_FILTERS = "getPriceListPositionsTableFilters";
export const GET_PRICE_LIST_POSITIONS = "getPriceListPositions";
export const GET_PRICE_LIST_POSITIONS_CONTACTS_COUNT = "getPriceListPositionsContactsCount";
export const GET_PRICE_LIST_POSITION_OFFERS_TABLE_COLUMNS = "getPriceListPositionOffersTableColumns";
export const GET_PRICE_LIST_POSITION_OFFERS_TABLE_FILTERS = "getPriceListPositionOffersTableFilters";
export const GET_PRICE_LIST_POSITION_OFFERS = "getPriceListPositionOffers";
export const GET_PRICE_LIST_POSITION = "showPriceListPosition";
export const GET_PRICE_LIST_MISSING_TRANSPORT_DATA = "getPriceListMissingTransportData";
export const GET_PRICE_LIST_PREVIEW_CONTENT = "getPriceListPreviewContent";
export const CREATE_PRICE_LIST = "createPriceList";
export const COPY_PRICE_LIST = "copyPriceList";
export const UPDATE_PRICE_LIST_NAME = "updatePriceListName";
export const UPDATE_PRICE_LIST_TYPE = "updatePriceListType";
export const UPDATE_PRICE_LIST_CONTACT = "updatePriceListContact";
export const UPDATE_PRICE_LIST_FOR_GROUP_OF_CONTACTS = "updatePriceListForGroupOfContacts";
export const UPDATE_PRICE_LIST_TEMP = "updatePriceListTemp";
export const UPDATE_PRICE_LIST_CURRENCY = "updatePriceListCurrency";
export const UPDATE_PRICE_LIST_INCLUDE_TRANSPORT_COST = "updatePriceListIncludeTransportCost";
export const UPDATE_PRICE_LIST_INCOTERM = "updatePriceListIncoterm";
export const UPDATE_PRICE_LIST_INCOTERM_DESTINATION_COUNTRY = "updatePriceListIncotermDestinationCountry";
export const UPDATE_PRICE_LIST_DESTINATION_COUNTRY = "updatePriceListDestinationCountry";
export const UPDATE_PRICE_LIST_INTERESTS = "updatePriceListInterests";
export const UPDATE_PRICE_LIST_CONTACT_SELECTION = "updatePriceListContactSelection";
export const UPDATE_PRICE_LIST_ALL_CONTACTS_SELECTED = "updatePriceListAllContactsSelected";
export const UPDATE_PRICE_LIST_POSITION_CONTACT_SELECTION = "updatePriceListPositionContactSelection";
export const UPDATE_PRICE_LIST_POSITION_ALL_CONTACTS_SELECTED = "updatePriceListPositionAllContactsSelected";
export const UPDATE_PRICE_LIST_DATE_TYPE = "updatePriceListDateType";
export const UPDATE_PRICE_LIST_WEEK = "updatePriceListWeek";
export const UPDATE_PRICE_LIST_START_DATE = "updatePriceListStartDate";
export const UPDATE_PRICE_LIST_END_DATE = "updatePriceListEndDate";
export const UPDATE_PRICE_LIST_POSITION_SELECTION = "updatePriceListPositionSelection";
export const UPDATE_PRICE_LIST_POSITIONS_SELECTION = "updatePriceListPositionsSelection";
export const CREATE_PRICE_LIST_REFRESH_REQUEST = "createPriceListRefreshRequest";
export const REFRESH_PRICE_LIST_SNAPSHOT = "refreshPriceListSnapshot";
export const UPDATE_PRICE_LIST_PUBLISHED_TYPE = "updatePriceListPublishedType";
export const UPDATE_PRICE_LIST_PUBLISHED_DATE = "updatePriceListPublishedDate";
export const UPDATE_PRICE_LIST_USER_SHARES = "updatePriceListUserShares";
export const UPDATE_PRICE_LIST_TEAM_SHARES = "updatePriceListTeamShares";
export const UPDATE_PRICE_LIST_POSITION_CLIENT_PRICE = "updatePriceListPositionClientPrice";
export const REFRESH_PRICE_LIST_POSITION_CLIENT_PRICE = "refreshPriceListPositionClientPrice";
export const UPDATE_PRICE_LIST_POSITION_LEAD_OFFER = "updatePriceListPositionLeadOffer";
export const UPDATE_PRICE_LIST_POSITION_TRANSPORT_COST = "updatePriceListPositionTransportCost";
export const REFRESH_PRICE_LIST_POSITION_TRANSPORT_COST = "refreshPriceListPositionTransportCost";
export const COPY_PRICE_LIST_POSITION = "copyPriceListPosition";
export const UPDATE_PRICE_LIST_POSITION_NOTE = "updatePriceListPositionNote";
export const PUBLISH_PRICE_LIST = "publishPriceList";
export const DELETE_PRICE_LIST = "deletePriceList";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_PRICE_LISTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_INTEREST_FIELDS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + '/interest-fields')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_CONTACTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${params.priceListId}/contacts`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_ALL_CONTACTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${params.priceListId}/all-contacts`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_CONTACTS_BY_POSITION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${params.priceListId}/contacts-by-position/${params.positionId}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITIONS_TABLE_COLUMNS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/list-positions/table-columns`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITIONS_TABLE_FILTERS](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}/list-positions/table-filters`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITIONS](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}/list-positions`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITIONS_CONTACTS_COUNT](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}/list-positions-contacts-count`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITION_OFFERS_TABLE_COLUMNS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/list-position-offers/table-columns`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITION_OFFERS_TABLE_FILTERS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/list-position-offers/${params.positionId}/table-filters`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITION_OFFERS](context, params) {
    return new Promise((resolve, reject) => {
      let positionId = params.positionId;
      delete params.positionId;

      ApiService.query(PATH + `/list-position-offers/${positionId}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_POSITION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${params.priceListId}/show-position/${params.positionId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_MISSING_TRANSPORT_DATA](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}/show-missing-transport-data`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [GET_PRICE_LIST_PREVIEW_CONTENT](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH + `/${priceListId}/show-preview-content`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [CREATE_PRICE_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(PATH, params)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [COPY_PRICE_LIST](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.post(PATH + `/${priceListId}/copy`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_NAME](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-name`, { name: params.name })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_TYPE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-type`, { type_id: params.type_id })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_CONTACT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-contact`, { contact_id: params.contact_id })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_FOR_GROUP_OF_CONTACTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-for-group-of-contacts`, {
        for_group_of_contacts: params.for_group_of_contacts
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_TEMP](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-temp`, {
        temp_mapping_value_id: params.temp_mapping_value_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_CURRENCY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-currency`, {
        currency_id: params.currency_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_INCLUDE_TRANSPORT_COST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-include-transport-cost`, {
        include_transport_cost: params.include_transport_cost
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_INCOTERM](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-incoterm`, {
        incoterm_id: params.incoterm_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_INCOTERM_DESTINATION_COUNTRY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-incoterm-destination-country`, {
        incoterm_destination_country_id: params.incoterm_destination_country_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_DESTINATION_COUNTRY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-destination-country`, {
        destination_country_id: params.destination_country_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_INTERESTS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-interests`, {
        interests: params.interests
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_CONTACT_SELECTION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-contact-selection`, {
        contact_id: params.contact_id,
        value: params.value
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_ALL_CONTACTS_SELECTED](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${priceListId}/update-all-contacts-selected`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_CONTACT_SELECTION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.priceListId}/update-position-contact-selection/${params.positionId}`, {
        contact_id: params.contactId,
        value: params.value
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_ALL_CONTACTS_SELECTED](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.priceListId}/update-position-all-contacts-selected/${params.positionId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_DATE_TYPE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-date-type`, {
        date_type_id: params.date_type_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_WEEK](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-week`, {
        week: params.week
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_START_DATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-start-date`, {
        start_date: params.start_date
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_END_DATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-end-date`, {
        end_date: params.end_date
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_SELECTION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-position-selection`, {
        live_list_position_snapshot_id: params.live_list_position_snapshot_id,
        value: params.value
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITIONS_SELECTION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-positions-selection`, {
        live_list_position_snapshots: params.live_list_position_snapshots,
        value: params.value
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [CREATE_PRICE_LIST_REFRESH_REQUEST](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${priceListId}/create-refresh-request`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [REFRESH_PRICE_LIST_SNAPSHOT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.priceListId}/refresh`, { refresh_request_id: params.refreshRequestId })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_PUBLISHED_TYPE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-published-type`, {
        published_type_id: params.published_type_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_PUBLISHED_DATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-published-date`, {
        published_date: params.published_date
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_USER_SHARES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-user-shares`, {
        users: params.users
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_TEAM_SHARES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.id}/update-team-shares`, {
        teams: params.teams
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_CLIENT_PRICE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/update-position-client-price/${params.positionId}`, {
        client_price: params.clientPrice
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [REFRESH_PRICE_LIST_POSITION_CLIENT_PRICE](context, positionId) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/refresh-position-client-price/${positionId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_LEAD_OFFER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/update-position-lead-offer/${params.id}`, {
        lead_offer_id: params.lead_offer_id
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_TRANSPORT_COST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/update-position-transport-cost/${params.positionId}`, {
        transport_cost: params.transportCost
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [REFRESH_PRICE_LIST_POSITION_TRANSPORT_COST](context, positionId) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/refresh-position-transport-cost/${positionId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [COPY_PRICE_LIST_POSITION](context, positionId) {
    return new Promise((resolve, reject) => {
      ApiService.post(PATH + `/copy-position/${positionId}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [UPDATE_PRICE_LIST_POSITION_NOTE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${params.priceListId}/update-position-note`, {
        live_list_position_snapshot_id: params.positionId,
        note: params.note
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [PUBLISH_PRICE_LIST](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.put(PATH + `/${priceListId}/publish`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [DELETE_PRICE_LIST](context, priceListId) {
    return new Promise((resolve, reject) => {
      ApiService.delete(PATH + `/${priceListId}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
