import Copper from 'copper-sdk';
import copperEntityMock from "@/common/mock/copper-entity.json";

export default {
  context: null,

	init() {
    //TODO: Initialize copper context and set it. The code below should works. https://docs.copper.com/copper-sdk/
    // return new Promise(resolve => {
    //   this.context = Copper.init();
    //   this.context.setAppUI({
    //     disableAddButton: true
    //   });
    //   this.context.on('needRefresh', () => {
    //     window.location.reload();
    //   });
    //   resolve();
    // });



    return new Promise(resolve => {
      window.parent.postMessage({ type: 'vims-load-context' }, '*');

      window.addEventListener('message', event => {
        if (event.data.type === 'vims-return-context') {
          this.context = event.data.data;

          resolve(true);
        }
      });
    });
	},

  resizeApplication(height) {
    //TODO: Resize application. The code below should works.
    // height = height < 200 ? 200 : height;
    // this.context.setAppUI({
    //   height: height
    // });


    //Chrome ext version
    height = height < 200 ? 200 : height;

    window.parent.postMessage({
      type: 'vims-resize-application',
      data: height
    }, '*');
  },

	getLoggedUserEmail() {
    //TODO: Get user email from real context. The code below should works.
    // return new Promise(async resolve => {
    //   let userInfo = await this.context.getUserInfo();
    //   resolve(userInfo.user.email);
    // });

    return new Promise((resolve, reject) => {
      if (!this.context || !this.context.user) {
        console.log(this.context);

        reject();
      }

      resolve(this.context.user.email);
    });
	},

  getCurrentEntity() {
    //TODO: Get entity from real context. The code below should works.
    // return new Promise(async resolve => {
    //   let entity = await this.context.getContext();
    //   resolve(entity);
    // });

    return new Promise(resolve => {
      window.parent.postMessage({ type: 'vims-get-current-entity' }, '*');

      window.addEventListener('message', event => {
        if (event.data.type === 'vims-return-current-entity') {
          resolve(event.data.data);
        }
      });
    });
  },

  onEvent(event, callback) {
    //TODO: Handle event. The code below should works.
    // this.context.on(event, callback);
  },

  openModalApplication(queryParams = {}, modalWidth = '600px') {
    //TODO: Open modal. The code below should works.
    // this.context.showModal(queryParams);

    window.parent.postMessage({
      type: 'vims-open-modal-application',
      data: { queryParams: queryParams, modalWidth: modalWidth }
    }, '*');
  },

  closeModalApplication() {
    //TODO: Close modal. The code below should works.
    // this.context.closeModal();

    window.parent.postMessage({ type: 'vims-close-modal-application' }, '*');
  },

  refreshUI(entityType) {
    //TODO: Refresh ui. The code below should works.
    // this.context.publishMessage('needRefresh', '*', {});

    window.parent.postMessage({ type: 'vims-refresh-application-ui' }, '*');
  }
};
