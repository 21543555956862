import ApiService from "@/common/api.service";
const PATH = 'mapping-values';

// action types
export const GET_MAPPING_VALUES = "getMappingValues";
export const GET_MAPPING_VALUES_GROUPED = "getMappingValuesGrouped";
export const GET_MAPPING_VALUES_TREE = "getMappingValuesTree";
export const CREATE_MAPPING_VALUE = "createMappingValue";
export const UPDATE_MAPPING_VALUE = "updateMappingValue";
export const REORDER_MAPPING_VALUES = "reorderMappingValues";
export const GET_ORDERED_MAPPING_VALUES = "getOrderedMappingValues";
export const DELETE_MAPPING_VALUE = "deleteMappingValue";
export const DOWNLOAD_MAPPING_VALUES = "downloaddMappingValues";
export const UPLOAD_MAPPING_VALUES = "uploadMappingValues";
export const GET_TEMPS = "getTemps";
export const SET_ORDERED_MAPPING_VALUES = "setOrderedMappingValues";
export const SET_ERROR = "setError";

// mutation types

const state = {
  orderedMappingValues: {},
  errors: {}
};

const getters = {
  orderedMappingValues(state) {
    return state.orderedMappingValues;
  },
};

const actions = {
    [GET_MAPPING_VALUES](context, params) {
        let id = params.id;

        params.id = undefined;

        return new Promise((resolve, reject) => {
            ApiService.query(PATH + '/list/' + id, params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [GET_MAPPING_VALUES_GROUPED](context, mappingId) {
      return new Promise((resolve, reject) => {
          ApiService.get(PATH + '/list-grouped', mappingId)
              .then((data) => {
                  resolve(data.data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [GET_MAPPING_VALUES_TREE](context, mappingId) {
      return new Promise((resolve, reject) => {
          ApiService.get(PATH + '/list-tree', mappingId)
              .then(({ data }) => {
                  resolve(data.data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [CREATE_MAPPING_VALUE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH, params)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [UPDATE_MAPPING_VALUE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH, payload.id, payload)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [REORDER_MAPPING_VALUES](context, params) {
      return new Promise((resolve, reject) => {
          ApiService.post(PATH + '/reorder', params)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [GET_ORDERED_MAPPING_VALUES](context, mappingId) {
      return new Promise((resolve, reject) => {
          ApiService.get(PATH + '/list-ordered-values', mappingId)
              .then((data) => {
                  resolve(data.data);

                  context.commit(
                    SET_ORDERED_MAPPING_VALUES,
                    { mappingId: mappingId, data: data.data }
                  );
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [DELETE_MAPPING_VALUE](context, id) {
      return new Promise((resolve, reject) => {
          ApiService.delete(PATH + '/' + id)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response);
              });
      });
    },

    [UPLOAD_MAPPING_VALUES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH+'/upload',payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response.data);
                });
        });
    },

    [DOWNLOAD_MAPPING_VALUES](context, id) {
      return new Promise((resolve, reject) => {
          ApiService.query(PATH + '/download-values/' + id)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch(({ response }) => {
                  reject(response.data);
              });
      });
    },

    [GET_TEMPS](context) {
      return new Promise((resolve, reject) => {
          ApiService.get(PATH + '/list-temps')
              .then(({ data }) => {
                  resolve(data.data);

                  context.commit(
                    SET_ORDERED_MAPPING_VALUES,
                    { mappingId: mappingId, data: data.data }
                  );
              })
              .catch(({ response }) => {
                  reject(response);
              });
      });
    }
};

const mutations = {
    [SET_ORDERED_MAPPING_VALUES](state, data) {
      state.orderedMappingValues[data.mappingId] = data.data;
    },

    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
