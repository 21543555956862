import ApiService from "@/common/api.service";
const PATH = 'vendorOfferSetImportTypes';

// action types
export const GET_VENDOR_OFFER_SET_IMPORT_TYPES = "getVendorOfferSetImportTypes";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [GET_VENDOR_OFFER_SET_IMPORT_TYPES](context, selects) {
    return new Promise((resolve, reject) => {
      ApiService.query(PATH)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters
};
